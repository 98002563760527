.radio-button-group {
  display: grid; }

.btn-item {
  border: none;
  width: 32%;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none; }
  .btn-item .radio-dot:before, .btn-item .radio-dot:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #fff;
    border-radius: 100%; }
  .btn-item .radio-dot::before {
    border: 2px solid #26A69A;
    width: 16px;
    height: 16px;
    top: 0%;
    left: 0%;
    margin-top: 10px; }
  .btn-item .radio-dot::after {
    width: 8px;
    height: 8px;
    top: 0%;
    left: 0%;
    margin-left: 4px;
    margin-top: 14px; }
  .btn-item .radio-button-item-word {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    width: auto; }

.radio-button-item {
  display: inline-block;
  position: relative;
  margin-right: 31%;
  margin-bottom: -2px;
  padding-left: 26px; }
  .radio-button-item input[type="radio"]:checked + span:after {
    background-color: #26A69A; }
  .radio-button-item input[type="radio"]:checked + span + span {
    color: rgba(62, 39, 35, 0.87); }
  .radio-button-item input[type="radio"]:disabled + span:after {
    background-color: #ccc; }
  .radio-button-item input[type="radio"]:disabled + span:before {
    border: 2px solid #ccc; }

.radio-button-item:hover .radio-dot:after {
  background-color: #26A69A; }

.not-checked {
  background-color: #ffffff;
  border-color: #ccc; }

.checked {
  background-color: #7d9c47; }

.radio-button-item > input[type="radio"] {
  position: absolute  !important;
  clip: rect(0, 0, 0, 0) !important;
  pointer-events: none; }

@media (max-width: 400px) {
  .mobile-br {
    display: none; }
    .mobile-br .radio-button-item {
      width: 49%;
      padding: 2%;
      position: relative; } }
