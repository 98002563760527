.notification-detail-modal .notification-form-container {
  padding: 16px 0px; }
  .notification-detail-modal .notification-form-container .notification-form-text-field div {
    background-color: white;
    color: black; }
    .notification-detail-modal .notification-form-container .notification-form-text-field div textarea {
      font-family: Roboto;
      font-size: 1.1rem;
      font-weight: lighter; }
  .notification-detail-modal .notification-form-container .controller-area {
    padding-top: 16px; }

.notification-detail-modal .arrow-button {
  padding: 0px; }
  .notification-detail-modal .arrow-button .arrow-icon {
    color: #6AA426;
    font-size: 3rem; }
    .notification-detail-modal .arrow-button .arrow-icon.disabled {
      color: #fff; }

.notification-detail-modal .last-button {
  background-color: #6AA426;
  color: #FFF;
  min-height: 44px;
  min-width: 155px;
  border-radius: 0px; }

.notification-detail-modal .video-player {
  width: 100%;
  position: relative; }
  .notification-detail-modal .video-player::before {
    display: block;
    content: "";
    padding-top: 56.25%; }
  .notification-detail-modal .video-player > .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.notification-detail-modal .stepper {
  justify-content: center;
  margin: 16px 0px;
  background: #fff; }

.notification-detail-modal .active-step {
  background-color: #6AA426; }
