.login-area {
  height: 100%;
  display: flex; }
  .login-area .login-area-form-container {
    flex-basis: 40%;
    font-family: 'Montserrat' ,sans-serif;
    height: 100%;
    min-width: 300px;
    transition: flex-basis 1s ease-in-out;
    z-index: 1;
    background-color: white; }
    .login-area .login-area-form-container .footer {
      justify-content: space-between;
      background-color: gray;
      position: absolute;
      bottom: 0;
      width: 40%;
      min-width: 300px;
      display: flex;
      align-items: center;
      height: 32px; }
      .login-area .login-area-form-container .footer .footer-social-networks {
        margin-left: 8px;
        margin-top: 4px; }
        .login-area .login-area-form-container .footer .footer-social-networks img {
          margin-right: 4px;
          width: 24px;
          height: 24px; }
      .login-area .login-area-form-container .footer .footer-logo {
        text-align: center; }
        .login-area .login-area-form-container .footer .footer-logo img {
          width: 72px;
          height: 24px;
          margin-top: 4px; }
      .login-area .login-area-form-container .footer .footer-text {
        margin-right: 10px;
        width: 31%; }
        .login-area .login-area-form-container .footer .footer-text label {
          font-size: 8px;
          color: white;
          float: right; }
      .login-area .login-area-form-container .footer .agrymet-logo {
        width: 48px;
        height: 32px; }
    .login-area .login-area-form-container .login-area-form-container-inside {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 32px;
      height: 100%; }
      .login-area .login-area-form-container .login-area-form-container-inside .login-form {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%; }
      .login-area .login-area-form-container .login-area-form-container-inside .contact-form-container-inside-subtitle {
        color: #8cb63c;
        font-weight: bold;
        text-align: center; }
      .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-title-row .login-area-form-container-inside-title {
        text-align: center; }
        .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-title-row .login-area-form-container-inside-title .logo-image {
          width: 80%; }
        .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-title-row .login-area-form-container-inside-title .logo-image-sign-in {
          width: 50%; }
      .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-title-row .login-area-form-container-inside-subtitle {
        color: #8cb63c;
        font-weight: bold;
        text-align: center; }
      .login-area .login-area-form-container .login-area-form-container-inside .options-row {
        margin-top: 8px; }
        .login-area .login-area-form-container .login-area-form-container-inside .options-row .forgot-password {
          cursor: pointer; }
          .login-area .login-area-form-container .login-area-form-container-inside .options-row .forgot-password label {
            cursor: pointer; }
        .login-area .login-area-form-container .login-area-form-container-inside .options-row .rc-checkbox .rc-checkbox-inner {
          border-color: #8cb63c; }
          .login-area .login-area-form-container .login-area-form-container-inside .options-row .rc-checkbox .rc-checkbox-inner::after {
            color: #8cb63c; }
        .login-area .login-area-form-container .login-area-form-container-inside .options-row .rc-checkbox-checked .rc-checkbox-inner {
          background-color: #8cb63c; }
      .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-form-row .errors {
        border: 1px solid #FF7F7F;
        color: #FF0000;
        padding: 8px; }
      .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-form-row .input-field {
        margin-top: 0;
        height: 64px;
        border: 1px solid #9e9e9e;
        padding-left: 8px;
        padding-top: 16px; }
        .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-form-row .input-field input {
          border: 0;
          margin-bottom: 0;
          height: 100%;
          line-height: 62px; }
        .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-form-row .input-field label {
          padding-left: 8px;
          padding-top: 8px; }
        .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-form-row .input-field .active {
          transform: translateY(-48%); }
      .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-form-row .input-active {
        border-left: 3px solid #8cb63c; }
      .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-buttons-row {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        margin-bottom: 16px; }
        .login-area .login-area-form-container .login-area-form-container-inside .login-area-form-container-inside-buttons-row .space-between {
          margin-left: 16px;
          font-size: 12px; }
    .login-area .login-area-form-container .signin-area-form-container-inside {
      padding: 8px 32px 32px 32px; }
  .login-area .login-area-image-container {
    flex-basis: 60%;
    height: 100%;
    transition: flex-basis 1s ease-in-out; }
    .login-area .login-area-image-container img {
      height: 100%;
      width: 100%; }
    .login-area .login-area-image-container .login-area-image-text {
      position: absolute;
      top: 25%;
      padding: 0 32px 0 32px;
      text-justify: inter-word; }

  @media screen and (max-width: 850px) {
    .login-area .login-area-form-container .login-area-form-container-inside {
      padding: 16px;
    }
  }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 1px solid #ccc !important;
  -webkit-text-fill-color: #707173 !important;
  -webkit-box-shadow: 0 0 0px 100px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important; }

.user-signed {
  color: #8cb63c;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px; }
