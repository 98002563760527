.form-control {
  min-width: 60px !important;
  height: 60px;
  margin-right: 28px !important;
  margin-top: 8px !important; }

.input-margin-8 {
  margin-top: 8px !important; }
  .input-margin-8 input {
    height: 30px !important; }
  .input-margin-8 button {
    padding: 0;
    position: absolute;
    top: 6px;
    right: 10px; }

.dashboard-settings .upload-context .file-container {
  max-width: 280px; }
  .dashboard-settings .upload-context .file-container input {
    height: 30px !important;
    margin-bottom: 0px !important; }

.dashboard-settings .upload-context .input-user-data {
  display: none; }

.dashboard-settings .upload-context .upload-icon {
  color: #6aa426;
  font-size: 36px; }

.dashboard-settings .line-add {
  margin-top: 16px !important;
  margin-top: 16px !important;
  height: 70px; }

.dashboard-upload-line {
  margin-top: 16px !important;
  height: 70px; }
  .dashboard-upload-line button {
    margin-right: 8px; }
  .dashboard-upload-line label {
    margin-right: 8px; }
  .dashboard-upload-line div {
    width: 250px; }
  .dashboard-upload-line input[type="text"] {
    height: 2rem;
    margin: 0px;
    color: black; }

.dashboard-table-container .table {
  margin: 0px 0px !important; }

.save-button {
  color: white !important; }

header.dashboard-header {
  z-index: 2;
  box-shadow: 0 4px 6px -5px black;
  height: 48px;
  padding: 6px 24px; }
  header.dashboard-header .title {
    text-align: center;
    justify-content: center;
    width: 100%;
    font-size: 1.1rem; }
  header.dashboard-header .dropdown-container .dropdown-div {
    z-index: 1;
    background-color: #fafafa;
    height: 75%;
    right: 80px; }
  header.dashboard-header .dropdown-container .dropdown-scroll {
    height: 79%; }
    @media screen and (min-height: 900px) {
      header.dashboard-header .dropdown-container .dropdown-scroll {
        height: 87%; } }
    header.dashboard-header .dropdown-container .dropdown-scroll .checkbox-item {
      display: flex;
      align-items: center;
      align-content: center;
      margin: 8px 0px; }
      header.dashboard-header .dropdown-container .dropdown-scroll .checkbox-item .item-label {
        display: flex;
        width: 100%; }
      header.dashboard-header .dropdown-container .dropdown-scroll .checkbox-item .checkbox {
        padding: 0px;
        width: 35px;
        height: 20px; }
      header.dashboard-header .dropdown-container .dropdown-scroll .checkbox-item .text {
        padding: 0px;
        width: 100%;
        line-height: 1;
        height: 20px; }
  header.dashboard-header .config-btn {
    padding: 4px; }

.dashboard-scroll {
  height: calc(100vh - 96px);
  position: absolute;
  width: 100%;
  background-color: #D5D5D5; }
  .dashboard-scroll .chart-container {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .dashboard-scroll .chart-container.row {
      height: 50%; }
    .dashboard-scroll .chart-container.padding-16 {
      padding: 16px; }
    .dashboard-scroll .chart-container .left {
      padding-left: 16px;
      padding-right: 8px; }
    .dashboard-scroll .chart-container .right {
      padding-left: 8px;
      padding-right: 16px; }
    .dashboard-scroll .chart-container .top {
      padding-top: 16px;
      padding-bottom: 8px; }
    .dashboard-scroll .chart-container .bottom {
      padding-top: 8px;
      padding-bottom: 16px; }
  .dashboard-scroll .chart {
    height: 100%;
    width: 100%;
    border-radius: 24px; }

.custom-tooltip {
  padding: 10px;
  line-height: 2rem;
  width: 100%;
  min-width: 80px;
  padding: 10px;
  line-height: 2rem; }

i.float-right {
  float: right;
  color: rgba(0, 0, 0, 0.25); }

.dialog-content p {
  margin: 0px; }

.dialog-content .productivity {
  margin: 0px; }

.dialog-content .efficiency {
  margin: 15px 0px; }
