.no-scroll {
  overflow: hidden; }

.profile-bar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-content: baseline;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  overflow-y: auto; }
  .profile-bar .line {
    margin: 16px 0px;
    border-bottom: 2px solid #6aa426;
    width: 90%;
    height: 1px; }
  .profile-bar .bold {
    font-weight: 600; }
  .profile-bar .icon-ref {
    float: right;
    padding: 5%; }
    .profile-bar .icon-ref .material-icons {
      color: gray;
      font-size: 30px; }
      .profile-bar .icon-ref .material-icons.green {
        background-color: #6aa426;
        color: #ffffff;
        text-align: start;
        font-size: 22px;
        height: 30px;
        width: 30px;
        padding: 4px; }
  .profile-bar .containerButtonsChangePasswordAndDeleteAccount {
    display: flex;
    justify-content: space-around;
    margin: 25px 0 0 0;
  }
  .profile-bar .buttonsChangePasswordAndDeleteAccount {
    background-color: #6AA426;
    color: #FFF
  }
  .profile-bar .user-header .user-summary {
    height: inherit;
    position: absolute;
    margin: auto 0px; }
  .profile-bar .user-products .margin-top {
    margin-top: 0.5vh; }
  .profile-bar .user-products .margin-bottom {
    margin-bottom: 5%; }
  .profile-bar .user-products .smart-items {
    height: 40px; }
    .profile-bar .user-products .smart-items .product-image {
      height: 40px;
      width: auto;
      padding: 0px 4px; }
  .profile-bar .user-points .profile-select-container {
    width: 80%; }
    .profile-bar .user-points .profile-select-container .profile-select {
      -moz-appearance: none;
      -webkit-appearance: none;
      text-align: center;
      display: inline;
      background-color: #6AA426;
      color: white;
      border-radius: 8px;
      border: none;
      height: 35px;
      background-size: 24px 24px;
      background-image: url("/images/others/indexW.png");
      background-repeat: no-repeat;
      background-position: 98% 50%;
      text-shadow: none; }
  .profile-bar .user-points .bold-text-container {
    font-weight: 600;
    margin: 8px 0px;
    width: 80%; }
  .profile-bar .user-points .point-container {
    width: 80%; }
    .profile-bar .user-points .point-container .point-list {
      width: 100%;
      background-color: #6aa426;
      height: 35px;
      border-radius: 8px;
      color: white;
      font-size: 1.1rem;
      font-weight: 400; }
  .profile-bar .user-download .download-container {
    width: 80%;
    margin-bottom: 16px; }
    .profile-bar .user-download .download-container .button {
      width: 100%;
      background-color: #6aa426;
      height: 35px;
      border-radius: 8px;
      color: white;
      font-size: 1.1rem;
      font-weight: 400; }

.user-page {
  height: 100%; }
  .user-page .title {
    font-style: italic;
    font-size: 22px;
    font-weight: 700;
    margin: 28px;
    color: green; }
  .user-page .personal-data {
    text-align: left;
    padding: 0% 2%;
    line-height: 1.2; }
    .user-page .personal-data .info {
      margin-bottom: 10px;
      margin-left: 16px; }
      .user-page .personal-data .info .info-header {
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        min-width: 170px; }
      .user-page .personal-data .info .info-value {
        font-size: 14px; }
      .user-page .personal-data .info .info-point-value {
        margin-top: 5%;
        font-size: 22px;
        font-style: italic;
        font-weight: 700; }
  .user-page .uploaded-data .filters {
    width: 100%;
    margin-bottom: 1%;
    padding-left: 40px; }
    .user-page .uploaded-data .filters .date {
      min-width: 450px; }
    .user-page .uploaded-data .filters .date-picker {
      text-align: center; }
      .user-page .uploaded-data .filters .date-picker input[type="text"] {
        margin: 0;
        height: 1.5rem; }
    .user-page .uploaded-data .filters .unit-form {
      width: 100%; }
      .user-page .uploaded-data .filters .unit-form .unit-picker {
        text-align: center;
        padding-bottom: 0;
        margin: 0;
        height: 1.5rem;
        min-width: 120px;
        width: 100%; }
    .user-page .uploaded-data .filters .button-grid {
      text-align: center; }
    .user-page .uploaded-data .filters button {
      padding: 5px;
      font-size: 12px;
      text-align: center; }
  .user-page .table {
    overflow: auto;
    width: 95vw;
    margin-top: 20px; }
    .user-page .table .header {
      font-size: 1rem; }
    .user-page .table .cell {
      font-size: 1rem;
      width: 15vw; }
    .user-page .table button {
      border-radius: 100px;
      padding: 0px;
      min-width: 20px;
      width: 30px;
      height: 30px; }
      .user-page .table button:focus {
        background-color: transparent; }
    .user-page .table input[type=number] {
      appearance: none;
      -moz-appearance: textfield;
      -ms-progress-appearance: none;
      -webkit-appearance: textfield;
      margin: 0;
      height: 1.5rem;
      width: 80px;
      text-align: center; }
      .user-page .table input[type=number]:after {
        border-bottom: 2px solid green; }
  .user-page .paper {
    height: 120px;
    width: 400px;
    text-align: center;
    padding: 50px;
    margin-top: 20px; }

.weather-download-modal {
  width: 100%; }
  .weather-download-modal .product-container {
    padding: 16px 60px; }
    .weather-download-modal .product-container .option--container {
      box-shadow: 2px 2px 4px #ccc;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 12px 8px 12px 8px;
      height: 75px;
      width: 100%;
      text-align: center;
      color: #737373;
      border-radius: 2px;
      position: relative;
      cursor: pointer; }
      .weather-download-modal .product-container .option--container::before {
        display: none; }
      .weather-download-modal .product-container .option--container.disabled {
        opacity: .2;
        background-color: transparent;
        pointer-events: none;
        box-shadow: 2px 2px 4px black; }
        .weather-download-modal .product-container .option--container.disabled .option--info {
          color: #ccc; }
      .weather-download-modal .product-container .option--container .option--info {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #ccc; }
      .weather-download-modal .product-container .option--container .option--container-title {
        margin: 0;
        font-size: 0.7rem; }
      .weather-download-modal .product-container .option--container img {
        height: 32px !important;
        width: 32px !important; }
  .weather-download-modal .point-list div label {
    height: 30px; }
  .weather-download-modal .point-list div span:last-child {
    font-size: 1rem; }
  .weather-download-modal .point-list div svg {
    color: #6AA426; }
  .weather-download-modal button {
    width: 15%;
    background-color: #6aa426;
    height: 35px;
    border-radius: 8px;
    color: white;
    font-size: 1.1rem;
    font-weight: 400; }

.kmz-modal {
  width: 100%;
  padding: 0 16px; }
  .kmz-modal .upload-icon {
    color: #6aa426;
    font-size: 2rem; }
