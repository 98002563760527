.screen {
  position: fixed;
  height: 100vh;
  width: 100vw; }

.map-buttons {
  position: absolute;
  bottom: 10%;
  right: 1%;
  width: 10% !important; }
  .map-buttons .invert-list {
    text-align: end; }
  .map-buttons li:not(:last-child) {
    margin-top: 0.5rem; }
  .map-buttons li:last-child {
    margin-bottom: 8px; }

.reportOpen {
  left: 1%;
  right: 100%; }

.lat-lng-show {
  position: absolute;
  bottom: 8.5%;
  right: 1%;
  background-color: #FAFAFA;
  font-weight: 400;
  color: black;
  font-size: 14px;
  padding: 2px 5px;
  border-radius: 2px; }

.map-info-show {
  position: absolute;
  top: 3%;
  right: 50%;
  background-color: #FAFAFA;
  font-weight: 400;
  color: black;
  font-size: 14px;
  padding: 2px 5px;
  border-radius: 2px; }

.map-colors-info {
  position: absolute;
  bottom: 250px;
  right: 1%;
  background-color: #FAFAFA;
  border-radius: 2px;
  margin-bottom: 8px;
  width: 10%; }
  @media screen and (min-height: 864px) {
    .map-colors-info {
      bottom: 275px;
    }
  }
  @media screen and (min-height: 1080px) {
    .map-colors-info {
      bottom: 280px;
    }
  }
  @media screen and (min-width: 1600px) {
    .map-colors-info {
      width: 7%; } }
  @media screen and (max-width: 1045px) {
    .map-colors-info {
      width: 12%; } }
  @media screen and (max-width: 890px) {
    .map-colors-info {
      width: 16%; } }
  @media screen and (max-width: 650px) {
    .map-colors-info {
      width: 18%; } }
  @media screen and (max-width: 545px) {
    .map-colors-info {
      width: 20%; } }
  @media screen and (max-width: 470px) {
    .map-colors-info {
      width: 26%; } }
  .map-colors-info .map-colors-variable-name-and-unit {
    font-size: 1rem;
    font-weight: 600; }
  .map-colors-info .inner-div {
    display: flex;
    width: 100%;
    height: 1rem;
    margin: 1px; }
    .map-colors-info .inner-div .colors-square {
      width: 30%; }
    .map-colors-info .inner-div .colors-square-transp:after {
      content: " ";
      position: absolute;
      border-top: 2px solid #7f7f7f;
      width: 26px;
      transform: rotate(27deg);
      transform-origin: 12% 0%; }
    .map-colors-info .inner-div .label-div {
      width: 70%;
      text-align: left;
      font-weight: 600;
      padding-left: 2px;
      line-height: 0.8rem; }
      .map-colors-info .inner-div .label-div label {
        color: black;
        font-size: 0.8rem;
        margin-left: 2px; 
        width: 200%; }

.map-colors-info-accumulate {
  width: 10%; }

.map-colors-info-index-widths .inner-div .colors-square {
  width: 20px; }

.map-colors-info-index-widths .inner-div .label-div {
  width: calc(100% - 20px); }

.map-colors-info-index-1 {
  width: 8%; }

.map-colors-info-index-2 {
  width: 9%; }

.map-colors-info-index-3 {
  width: 10%; }

.map-colors-info-index-4 {
  width: 15%; }

.map-colors-info-report-open {
  left: 1%; }

.lat-lng-report-open {
  left: 1%;
  right: auto; }
  @media screen and (min-width: 1402px) {
    .lat-lng-report-open {
      left: 0.7%; } }
  @media screen and (min-width: 1602px) {
    .lat-lng-report-open {
      left: 0.5%; } }

.map-info-report-open {
  left: 6%;
  right: auto; }
  @media screen and (min-width: 1402px) {
    .map-info-report-open {
      left: 8%; } }
  @media screen and (min-width: 1602px) {
    .map-info-report-open {
      left: 10%; } }

.extbh-info {
  left: 9%;
  right: auto; }
  @media screen and (min-width: 1402px) {
    .extbh-info {
      left: 11%; } }
  @media screen and (min-width: 1602px) {
    .extbh-info {
      left: 13%; } }

.atr-help {
  width: 100%;
  position: absolute;
  height: calc(100% - 48px);
  background-color: white;
  top: 48px;
  z-index: 99; }
  .atr-help .title {
    text-align: center;
    margin-bottom: 0px !important; }
  .atr-help .help-item img {
    float: left;
    width: 60%;
    padding: 16px 8px 8px 8px; }
  .atr-help .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer; }
    .atr-help .close-icon i {
      color: #26a69a; }

.map-annual-info {
  position: absolute;
  top: 95%;
  background-color: #FAFAFA;
  font-weight: 400;
  color: red;
  font-size: 14px; }

.map-annual-info-report-open {
  left: 10%; }

.map-annual-info-report-closed {
  right: 10%; }
