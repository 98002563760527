div.main-container {
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding: 8px;
}

header {
  text-align: center;
}

img.header-image {
  width: 100%;
}

main {
  text-align: center;
}

div.message-container {
  margin: 92px 0;
}

h2.subscription {
  color: #ea6f00;
  font-family: arial, helvetica, sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 35px;
}

p { 
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
}

div.social-container {
  padding: 25px 0;
  background-color: rgb(235, 241, 229);
}

img.icon-image {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  margin: 0 7px;
  background-color: #000000;
}

div.info-container > h4, .info > p {
  font-size: 11px;
}

div.info-container > h4 {
  font-weight: bold;
}

footer {
  margin-top: 20px;
}

a.unsubscribe {
  text-decoration: none;
  color: #000000;
  font-size: 12px;
}

@media screen and (max-width:480px) {
  div.message-container {
    margin: 70px 0;
  }

  h2.subscription {
    font-size: 1.3em;
  }
}