.integration-page {
  height: 100%; }
  .integration-page .MuiInput-underline-140::before {
    border-bottom: 1px solid green; }
  .integration-page .MuiInput-underline-140::after {
    border-bottom: 2px solid green; }
  .integration-page strong {
    font-weight: 600; }
  .integration-page button:focus {
    background-color: transparent; }
  .integration-page .title {
    font-style: italic;
    font-size: 22px;
    font-weight: 700;
    color: green;
    margin: 28px;
    margin-bottom: 8px; }
  .integration-page .first-row {
    height: 210px; }
    .integration-page .first-row .keys-paper {
      margin: 0 40px;
      text-align: left;
      line-height: 2.2rem;
      height: 30vh;
      min-height: 140px;
      min-width: 280px; }
      .integration-page .first-row .keys-paper .keys-container {
        height: 150px; }
      .integration-page .first-row .keys-paper .row {
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        min-width: 170px; }
        .integration-page .first-row .keys-paper .row.margin-right {
          margin-right: 24px; }
        .integration-page .first-row .keys-paper .row .key-header {
          min-height: 38px; }
        .integration-page .first-row .keys-paper .row div input {
          margin: 0;
          height: 1.2rem;
          font-size: .9rem;
          font-weight: 500;
          text-align: center; }
      .integration-page .first-row .keys-paper .keys-button {
        text-align: end; }
        .integration-page .first-row .keys-paper .keys-button button {
          width: 50%; }
  .integration-page .input-form {
    padding-left: 40px;
    line-height: 2.2rem; }
    .integration-page .input-form div:first-child {
      margin-right: 24px; }
    .integration-page .input-form .button-grid {
      margin-left: -5%; }
    .integration-page .input-form .row {
      white-space: nowrap;
      text-align: left;
      font-weight: 700;
      font-size: 14px;
      min-width: 170px; }
      .integration-page .input-form .row.label {
        margin-bottom: 0px; }
      .integration-page .input-form .row .header {
        width: auto;
        min-width: 0; }
      .integration-page .input-form .row .selector div:first-child {
        width: 220px;
        padding-right: 0; }
      .integration-page .input-form .row .station div:first-child {
        width: 150px;
        padding-right: 0; }
      .integration-page .input-form .row .station .helper {
        margin: 0px 0px 12px 0px; }
      .integration-page .input-form .row input {
        margin: 0;
        height: 1.5rem;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        width: 140px; }
