@charset "UTF-8";
.table {
  margin: 0px 32px; }
  .table .header-row {
    background-color: #f2f2f2; }
  .table input[type=text] {
    margin: 0;
    width: 80px;
    text-align: center;
    font-size: .9rem;
    line-height: 1.2rem;
    height: 25px; }
    .table input[type=text]:after {
      border-bottom: 2px solid green; }
  .table .MuiInput-underline-140::before {
    border-bottom: 1px solid green; }
  .table .MuiInput-underline-140::after {
    border-bottom: 2px solid green; }
  .table button {
    border-radius: 100px;
    padding: 0px;
    min-width: 20px;
    width: 30px;
    height: 30px; }
    .table button:focus {
      background-color: transparent; }
  .table .header-cell {
    font-size: 1rem;
    font-weight: 400;
    color: black; }
  .table .row {
    font-size: .9rem;
    line-height: 1.2rem;
    height: 25px; }
    .table .row .button-cell {
      width: 30px; }

.notification-table {
  margin: 0px;
  border: 1px solid black; }
  .notification-table thead {
    background-color: #4CAF50;
    border-bottom: 1px solid black; }
    .notification-table thead .header-cell {
      font-size: 1rem;
      font-weight: 400;
      color: white; }
      .notification-table thead .header-cell:nth-child(1) {
        width: 55%;
        text-align: initial; }
      .notification-table thead .header-cell:nth-child(2), .notification-table thead .header-cell:nth-child(3) {
        width: 15%; }
      .notification-table thead .header-cell:last-child {
        text-align: center; }
    .notification-table thead .header-button::after {
      content: "Ações"; }
  .notification-table .cell {
    font-size: .9rem;
    line-height: 1.2rem;
    height: 25px; }
    .notification-table .cell:nth-child(1) {
      width: 50%;
      text-align: initial; }
    .notification-table .cell:nth-child(2), .notification-table .cell:nth-child(3) {
      width: 15%; }
  .notification-table .button-cell {
    width: 20%;
    text-align: center; }
