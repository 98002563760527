.sidebar {
  position: absolute;
  height: calc(100vh - 48px);
  width: 376px;
  top: 48px;
  left: -378px;
  transition: all .3s ease-out;
  background-color: white;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  z-index: 9; }
  .sidebar.active {
    left: 0; }
    .sidebar.active .container--toggler i {
      transform: rotate(180deg); }
    .sidebar.active .container--toggler.container--toggler-right i {
      transform: none; }
  .sidebar .sidebar--content {
    overflow: auto;
    height: 100%; }
  .sidebar .scrollbar-container {
    background-color: #fafafa; }
  .sidebar .button div {
    padding: 16px 16px 8px 16px !important; }
  .sidebar .id-field {
    width: 20%;
    padding-left: 12px;
    position: absolute;
    top: 4px;
    color: rgba(0, 0, 0, 0.26);
    font-size: 14px; }
  .sidebar .farmForm--body {
    padding: 20px 16px 16px !important;
    overflow: hidden;
    background-color: #fafafa; }
    .sidebar .farmForm--body label {
      color: #737373 !important;
      font-size: 12px;
      text-transform: uppercase; }
    .sidebar .farmForm--body input[type="text"] {
      border-bottom: 1px solid rgba(62, 39, 35, 0.54);
      font-size: 16px;
      color: #737373; }
    .sidebar .farmForm--body .length-label {
      font-size: 10px;
      margin-top: 35px; }
      .sidebar .farmForm--body .length-label.active {
        font-size: 10px;
        margin-top: 57px; }
    .sidebar .farmForm--body .top-input-fields {
      margin-top: 2rem; }
    .sidebar .farmForm--body .select-field {
      margin: 0; }
    .sidebar .farmForm--body .coords-div .input-field label::after {
      font-size: 0.6rem; }
    .sidebar .farmForm--body #cad-div {
      display: flex; }
      .sidebar .farmForm--body #cad-div label {
        font-size: 0.8rem; }
      .sidebar .farmForm--body #cad-div span {
        font-size: 14px;
        margin-left: 16px;
        color: #737373; }
      .sidebar .farmForm--body #cad-div #help-cad {
        font-size: 13px;
        color: rgba(165, 42, 42, 0.2); }
  .sidebar .list-circle {
    height: 30px;
    width: 30px;
    text-align: center;
    font-weight: 500;
    border-radius: 50%;
    display: inline-block;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
    background-color: color("teal", "accent-4");
    color: rgba(62, 39, 35, 0.87);
    border: 1px solid rgba(62, 39, 35, 0.87); }
  .sidebar .farm-label-size-1 {
    font-size: 16px; }
  .sidebar .farm-label-size-2 {
    font-size: 14px; }
  .sidebar .farm-label-size-3 {
    font-size: 12px; }
  .sidebar .farm-label-size-4 {
    font-size: 8px; }
  .sidebar .farms-list {
    border-top: 1px solid black;
    margin-top: 8px;
    padding-top: 40px; }
  .sidebar .search-div {
    display: flex;
    margin-bottom: -25px;
    background-color: #fafafa;
    position: fixed;
    width: 29%; }
    @media screen and (min-width: 1302px) {
      .sidebar .search-div {
        width: 377px;
        z-index: 1;
        height: 50px;
        border-bottom: 1px solid rgba(62, 39, 35, 0.32);
        margin-top: -8px; } }
    .sidebar .search-div .search-icon {
      font-size: 22px;
      margin-top: 15px;
      margin-left: 40px;
      color: #737373; }
  .sidebar .search-input {
    width: 80%;
    padding-left: 16px; }
    .sidebar .search-input input[type="search"] {
      border-bottom: 0px; }
      .sidebar .search-input input[type="search"]:focus:not([readonly]) {
        box-shadow: 0 0 0 0 white; }
  .sidebar select:active {
    border-color: #fafafa;
    outline-color: #fafafa; }
  .sidebar .form-divider {
    background-color: #737373;
    padding: 10px;
    height: 48px;
    line-height: 48px; }
    .sidebar .form-divider label {
      color: white;
      font-size: 1rem; }
  .sidebar .reports {
    padding: 5px 10px !important; }
    .sidebar .reports .sub-products-select-field {
      display: flex;
      justify-content: center;
      margin: 0; }
    .sidebar .reports .sub-products {
      margin-top: 60px; }
    .sidebar .reports .option--container {
      box-shadow: 2px 2px 4px #ccc;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 24px 8px 8px 8px;
      height: 100px;
      width: 100%;
      text-align: center;
      color: #737373;
      border-radius: 2px;
      margin-bottom: 15px;
      position: relative;
      cursor: pointer; }
      .sidebar .reports .option--container::before {
        display: none; }
      .sidebar .reports .option--container.disabled {
        opacity: .2;
        background-color: transparent;
        pointer-events: none;
        box-shadow: 2px 2px 4px black; }
        .sidebar .reports .option--container.disabled .option--info {
          color: #ccc; }
      .sidebar .reports .option--container.active {
        box-shadow: inset 0px 0px 0px 5px #00A599, 2px 2px 4px #ccc; }
        .sidebar .reports .option--container.active .option--info {
          color: #00A599; }
      .sidebar .reports .option--container .option--info {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #ccc; }
      .sidebar .reports .option--container .option--container-title {
        margin: 0;
        font-size: 0.7rem; }
  .sidebar footer {
    padding: 10px 10px 0 !important;
    color: #737373; }
    .sidebar footer input[type="submit"] {
      color: #737373;
      background-color: #ffd740; }

.cadError {
  border: 1px solid red !important; }

.user-data-field {
  margin-top: 20px;
  margin-bottom: 15px; }

.user-data {
  overflow: hidden;
  position: relative;
  width: 100%;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  background-color: inherit;
  text-transform: uppercase; }
  .user-data .user-data-labels {
    font-size: 11px;
    font-weight: normal;
    text-align: left;
    width: 70%; }
  .user-data .user-data-icon {
    width: 15%;
    color: rgba(62, 39, 35, 0.87); }
    .user-data .user-data-icon i {
      font-size: 25px;
      margin-left: 68px; }
  .user-data input[type="file"] {
    cursor: inherit;
    display: none;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0; }

.disabled {
  color: #737373 !important;
  text-shadow: 0 0 0 rgba(62, 39, 35, 0.13) !important;
  border-color: rgba(62, 39, 35, 0.13) !important;
  background-image: none !important; }

.sidebar-select {
  border-bottom: 2px solid #4CAF50 !important; }

.soil-div {
  margin-top: 16px;
  padding: 0 !important; }
  .soil-div div {
    height: 30px;
    width: 100%;
    vertical-align: middle; }
    .soil-div div select {
      height: 40px; }
