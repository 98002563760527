nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
  line-height: 48px;
  height: 48px; }
  nav .notification-list, nav .nav-wrapper i .notification-list, nav a.button-collapse .notification-list, nav a.button-collapse i .notification-list {
    display: flex;
    z-index: 1;
    align-items: last baseline;
    position: absolute;
    top: 40px;
    right: 120px;
    padding: 6px 8px;
    width: 400px; }
    nav .notification-list ul, nav .nav-wrapper i .notification-list ul, nav a.button-collapse .notification-list ul, nav a.button-collapse i .notification-list ul {
      max-height: 250px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: #6AA426 #F0F0F0; }
      nav .notification-list ul::-webkit-scrollbar, nav .nav-wrapper i .notification-list ul::-webkit-scrollbar, nav a.button-collapse .notification-list ul::-webkit-scrollbar, nav a.button-collapse i .notification-list ul::-webkit-scrollbar {
        width: 6px; }
      nav .notification-list ul::-webkit-scrollbar-track, nav .nav-wrapper i .notification-list ul::-webkit-scrollbar-track, nav a.button-collapse .notification-list ul::-webkit-scrollbar-track, nav a.button-collapse i .notification-list ul::-webkit-scrollbar-track {
        background: #F0F0F0; }
      nav .notification-list ul::-webkit-scrollbar-thumb, nav .nav-wrapper i .notification-list ul::-webkit-scrollbar-thumb, nav a.button-collapse .notification-list ul::-webkit-scrollbar-thumb, nav a.button-collapse i .notification-list ul::-webkit-scrollbar-thumb {
        background: #6AA426; }
    nav .notification-list li, nav .nav-wrapper i .notification-list li, nav a.button-collapse .notification-list li, nav a.button-collapse i .notification-list li {
      width: 100%;
      padding: 4px 0px; }
      nav .notification-list li a, nav .nav-wrapper i .notification-list li a, nav a.button-collapse .notification-list li a, nav a.button-collapse i .notification-list li a {
        width: 100%; }
    nav .notification-list .notification-item, nav .nav-wrapper i .notification-list .notification-item, nav a.button-collapse .notification-list .notification-item, nav a.button-collapse i .notification-list .notification-item {
      width: 100%;
      color: black; }
      nav .notification-list .notification-item div, nav .nav-wrapper i .notification-list .notification-item div, nav a.button-collapse .notification-list .notification-item div, nav a.button-collapse i .notification-list .notification-item div {
        line-height: 25px; }

nav .sidebar-header {
  background-color: #55940B;
  width: 376px;
  height: 100%;
  z-index: 1; }
  nav .sidebar-header i {
    line-height: 37px; }
  nav .sidebar-header .button--more-farms-active i {
    line-height: 32px; }
  nav .sidebar-header .formItem {
    background-color: white;
    width: 100%; }
    nav .sidebar-header .formItem .formItem--input {
      width: calc(100% - 82px); }
    nav .sidebar-header .formItem input[type="text"] {
      background-color: white;
      border: 0;
      color: black;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      height: 40px; }
  nav .sidebar-header ul {
    margin: 0; }
    nav .sidebar-header ul li {
      float: left;
      height: 32px; }
      nav .sidebar-header ul li a {
        padding: 0; }
        nav .sidebar-header ul li a:hover {
          background-color: white; }
      nav .sidebar-header ul li input[type="radio"], nav .sidebar-header ul li label::after, nav .sidebar-header ul li label::before {
        display: none; }
      nav .sidebar-header ul li [type="radio"]:not(:checked) + label {
        padding-left: 0; }
      nav .sidebar-header ul li [type="radio"] + label, nav .sidebar-header ul li [type="radio"]:checked + label {
        transition: none;
        background-color: #6AA426;
        margin-left: 8px;
        margin-top: 8px;
        color: rgba(62, 39, 35, 0.54);
        font-weight: 500;
        border: 1px solid #004d40;
        border-radius: 50%;
        text-align: center;
        padding-left: 0;
        height: 32px;
        width: 32px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center; }
      nav .sidebar-header ul li [type="radio"]:checked + label {
        color: white;
        border: 2px solid white; }
  nav .sidebar-header .farm-label-size-1 {
    font-size: 16px !important; }
  nav .sidebar-header .farm-label-size-2 {
    font-size: 14px !important; }
  nav .sidebar-header .farm-label-size-3 {
    font-size: 12px !important; }
  nav .sidebar-header .farm-label-size-4 {
    font-size: 8px !important; }

nav .sidebar-inactive {
  background-color: rgba(0, 105, 92, 0);
  height: 48px; }

nav .brand-logo {
  white-space: normal;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 5px; }

nav .landing-logo {
  top: -26px;
  cursor: pointer; }

nav #application-dropdown {
  background-color: #eee;
  min-width: 250px; }
  nav #application-dropdown li:first-child {
    pointer-events: none;
    cursor: initial;
    text-align: center;
    min-height: 0;
    padding: 10px 0; }

nav input[type="text"] {
  margin: 0 0 0 4px;
  background-color: white;
  padding: 0 10px;
  border: 0;
  color: black;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.login-modal {
  width: 25%;
  position: absolute;
  z-index: 9999;
  background-color: white;
  top: 150px;
  left: 35%; }
  .login-modal .login-title {
    background-color: #26a69a;
    padding: 0 20px 0 20px;
    font-weight: 500; }
  .login-modal .login-inputs {
    padding: 0 20px 0 20px; }
    .login-modal .login-inputs .input-field label:not(.label-icon).active {
      transform: translateY(-55%); }
    .login-modal .login-inputs .input-field label:after.active {
      margin-top: 20px; }
    .login-modal .login-inputs .input-field input {
      padding-left: 0;
      color: rgba(62, 39, 35, 0.87); }
    .login-modal .login-inputs .input-field input[type="email"] + label::after {
      top: 25px; }
    .login-modal .login-inputs .input-field input[type="password"] + label::after {
      top: 22px; }
      .login-modal .login-inputs .input-field input[type="password"] + label::after.active {
        margin-top: 20px; }
    .login-modal .login-inputs .input-field #identifier, .login-modal .login-inputs .input-field #password {
      border-bottom: 1px solid #ccc; }
  .login-modal .errors {
    background-color: rgba(255, 0, 0, 0.3);
    color: red;
    padding: 0 10px 0 10px;
    font-size: 13px; }

.signin-modal-parent {
  width: 100%;
  height: 80%;
  background-color: white; }
  .signin-modal-parent .rc-steps {
    margin-bottom: 16px; }
    .signin-modal-parent .rc-steps .rc-steps-item .rc-steps-item-icon {
      border-color: #8cb63c; }
    .signin-modal-parent .rc-steps .rc-steps-item .rc-steps-item-content {
      font-size: 10px; }
    .signin-modal-parent .rc-steps .rc-steps-item-process .rc-steps-item-icon {
      background: #8cb63c; }
    .signin-modal-parent .rc-steps .rc-steps-item-finish .rc-steps-item-tail::after {
      background-color: #8cb63c; }
  .signin-modal-parent .errors {
    border: 1px solid #FF7F7F;
    color: #FF0000;
    padding: 8px;
    margin-bottom: -8px !important; }
  .signin-modal-parent .login-title {
    background-color: #26a69a;
    padding: 0 20px 0 20px;
    font-weight: 500;
    text-align: center; }
  .signin-modal-parent .signin-modal-user-data .input-field label:not(.label-icon).active {
    transform: translateY(-80%); }
  .signin-modal-parent .signin-modal-user-type, .signin-modal-parent .signin-modal-user-data, .signin-modal-parent .signin-modal-user-culture {
    height: 100%;
    background-color: white; }
    .signin-modal-parent .signin-modal-user-type .type-description, .signin-modal-parent .signin-modal-user-data .type-description, .signin-modal-parent .signin-modal-user-culture .type-description {
      padding: 20px 50px 0 50px;
      text-align: center; }
      .signin-modal-parent .signin-modal-user-type .type-description p, .signin-modal-parent .signin-modal-user-data .type-description p, .signin-modal-parent .signin-modal-user-culture .type-description p {
        color: #9e9e9e; }
    .signin-modal-parent .signin-modal-user-type .type-option, .signin-modal-parent .signin-modal-user-data .type-option, .signin-modal-parent .signin-modal-user-culture .type-option {
      box-shadow: 2px 2px 15px #ccc;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 10px 20px 10px 20px;
      height: 55px;
      text-align: center;
      color: rgba(62, 39, 35, 0.87);
      border-radius: 2px;
      margin-bottom: 15px;
      position: relative;
      cursor: pointer; }
      .signin-modal-parent .signin-modal-user-type .type-option .option--container-title, .signin-modal-parent .signin-modal-user-data .type-option .option--container-title, .signin-modal-parent .signin-modal-user-culture .type-option .option--container-title {
        font-size: 14px; }
      .signin-modal-parent .signin-modal-user-type .type-option:hover label i, .signin-modal-parent .signin-modal-user-data .type-option:hover label i, .signin-modal-parent .signin-modal-user-culture .type-option:hover label i {
        color: #26a69a; }
      .signin-modal-parent .signin-modal-user-type .type-option:hover .option--container-title, .signin-modal-parent .signin-modal-user-data .type-option:hover .option--container-title, .signin-modal-parent .signin-modal-user-culture .type-option:hover .option--container-title {
        color: #8cb63c; }
      .signin-modal-parent .signin-modal-user-type .type-option label i, .signin-modal-parent .signin-modal-user-data .type-option label i, .signin-modal-parent .signin-modal-user-culture .type-option label i {
        line-height: 13px;
        height: 25px;
        font-size: 50px;
        margin-top: 8px; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:not(:checked) + label, .signin-modal-parent .signin-modal-user-type .type-option [type="radio"]:checked + label, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:not(:checked) + label, .signin-modal-parent .signin-modal-user-data .type-option [type="radio"]:checked + label, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:not(:checked) + label, .signin-modal-parent .signin-modal-user-culture .type-option [type="radio"]:checked + label {
        padding-left: 0;
        line-height: 15px; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:not(:checked) + label::before, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:not(:checked) + label::before, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:not(:checked) + label::before {
        border: 0; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:checked + label::before, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:checked + label::before, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:checked + label::before {
        border-radius: 0;
        border: 0; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:not(:checked) + label::before, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:not(:checked) + label::before, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:not(:checked) + label::before {
        border-radius: 0;
        border: 0; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:not(:checked) + label::after, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:not(:checked) + label::after, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:not(:checked) + label::after {
        border-radius: 0;
        border: 0; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:checked + label::before, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:checked + label::before, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:checked + label::before {
        border-radius: 0;
        border: 0; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:checked + label::after, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:checked + label::after, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:checked + label::after {
        border-radius: 0;
        border: 0;
        background-color: white; }
      .signin-modal-parent .signin-modal-user-type .type-option .with-gap[type="radio"]:checked + label::before, .signin-modal-parent .signin-modal-user-data .type-option .with-gap[type="radio"]:checked + label::before, .signin-modal-parent .signin-modal-user-culture .type-option .with-gap[type="radio"]:checked + label::before {
        border-radius: 0;
        border: 0; }
      .signin-modal-parent .signin-modal-user-type .type-option .with-gap[type="radio"]:checked + label::after, .signin-modal-parent .signin-modal-user-data .type-option .with-gap[type="radio"]:checked + label::after, .signin-modal-parent .signin-modal-user-culture .type-option .with-gap[type="radio"]:checked + label::after {
        border-radius: 0;
        border: 0;
        background-color: #FAFAFA; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:not(:checked) + label, .signin-modal-parent .signin-modal-user-type .type-option input[type="radio"]:checked + label, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:not(:checked) + label, .signin-modal-parent .signin-modal-user-data .type-option input[type="radio"]:checked + label, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:not(:checked) + label, .signin-modal-parent .signin-modal-user-culture .type-option input[type="radio"]:checked + label {
        height: 100%;
        width: 100%;
        display: inline; }
      .signin-modal-parent .signin-modal-user-type .type-option input[type=radio]:checked ~ .check::before, .signin-modal-parent .signin-modal-user-data .type-option input[type=radio]:checked ~ .check::before, .signin-modal-parent .signin-modal-user-culture .type-option input[type=radio]:checked ~ .check::before {
        background-color: white; }
      .signin-modal-parent .signin-modal-user-type .type-option .option--container-title, .signin-modal-parent .signin-modal-user-data .type-option .option--container-title, .signin-modal-parent .signin-modal-user-culture .type-option .option--container-title {
        font-size: 18px; }

.signin-modal-user-data {
  position: relative;
  padding: 0 20px 0 20px; }
  .signin-modal-user-data .button {
    margin-top: 24px;
    margin-bottom: 0 !important; }
  .signin-modal-user-data .signin-row {
    margin-bottom: 0; }
    .signin-modal-user-data .signin-row.user-points {
      display: flex;
      align-items: center; }
    .signin-modal-user-data .signin-row .lds-spinner {
      color: inherit;
      display: inline-block;
      position: relative;
      width: 30px;
      height: 30px;
      margin-left: -25px;
      margin-top: 5px; }
    .signin-modal-user-data .signin-row .lds-spinner div {
      transform-origin: 32px 32px;
      animation: lds-spinner 1.2s linear infinite; }
    .signin-modal-user-data .signin-row .lds-spinner div:after {
      content: " ";
      display: block;
      position: absolute;
      top: 22px;
      left: 30px;
      width: 2px;
      height: 7px;
      border-radius: 20%;
      background: #4CAF50; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s; }
    .signin-modal-user-data .signin-row .lds-spinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s; }

@keyframes lds-spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .signin-modal-user-data .first-row {
    margin-top: 10px; }
  .signin-modal-user-data .input-field {
    height: 5%;
    margin-top: 10px; }
    .signin-modal-user-data .input-field label:not(.label-icon).active {
      transform: translateY(-55%); }
      .signin-modal-user-data .input-field label:not(.label-icon).active::after {
        transform: translateY(55%); }
    .signin-modal-user-data .input-field input {
      padding-left: 0;
      color: rgba(62, 39, 35, 0.87); }
    .signin-modal-user-data .input-field input[type="email"] + label::after, .signin-modal-user-data .input-field input[type="password"] + label::after, .signin-modal-user-data .input-field input[type="text"] + label::after {
      top: 40px;
      font-size: 12px; }
    .signin-modal-user-data .input-field #identifier, .signin-modal-user-data .input-field #password, .signin-modal-user-data .input-field #passwordConfirm, .signin-modal-user-data .input-field #cpf_cnpj, .signin-modal-user-data .input-field #cep, .signin-modal-user-data .input-field #address, .signin-modal-user-data .input-field #number, .signin-modal-user-data .input-field #cidade, .signin-modal-user-data .input-field #bairro, .signin-modal-user-data .input-field #estado {
      border-bottom: 1px solid #ccc; }

.signin-modal-user-culture {
  position: relative; }
  .signin-modal-user-culture .input-field {
    height: 5%; }
    .signin-modal-user-culture .input-field label:not(.label-icon).active {
      transform: translateY(-55%); }
      .signin-modal-user-culture .input-field label:not(.label-icon).active::after {
        transform: translateY(55%); }
    .signin-modal-user-culture .input-field input {
      padding-left: 0;
      color: rgba(62, 39, 35, 0.87); }
    .signin-modal-user-culture .input-field input[type="email"] + label::after, .signin-modal-user-culture .input-field input[type="password"] + label::after, .signin-modal-user-culture .input-field input[type="text"] + label::after {
      top: 25px;
      font-size: 12px; }
    .signin-modal-user-culture .input-field #otherCultures, .signin-modal-user-culture .input-field #otherCultures2 {
      border-bottom: 1px solid #ccc; }
  .signin-modal-user-culture .button {
    margin-top: 24px;
    margin-bottom: 0 !important; }
  .signin-modal-user-culture .cultures-label label {
    font-size: 16px; }
  .signin-modal-user-culture .culture-option {
    margin-bottom: 8px; }
    .signin-modal-user-culture .culture-option .rc-checkbox-inner {
      font-size: 16px;
      border-color: #8cb63c;
      border-width: 2px;
      width: 16px;
      height: 16px; }
      .signin-modal-user-culture .culture-option .rc-checkbox-inner::after {
        color: #8cb63c;
        left: 3px;
        top: 1px; }
    .signin-modal-user-culture .culture-option .rc-checkbox-checked .rc-checkbox-inner {
      background-color: #8cb63c; }

.toast-alert-container {
  margin-top: 36px;
  text-align: left;
  width: 376px; }
