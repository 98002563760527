.last-key {
  border-right: 1px solid black; }

.bhbox--indicator-color {
  height: 20px;
  cursor: pointer; }

.bhbox--indicator-color[data-level="0"] {
  background-color: #0000FF; }

.bhbox--indicator-color[data-level="1"] {
  background-color: #16A53F; }

.bhbox--indicator-color[data-level="2"] {
  background-color: #FFFF54; }

.bhbox--indicator-color[data-level="3"] {
  background-color: #FEC30A; }

.bhbox--indicator-color[data-level="4"] {
  background-color: #D9560D; }

.bhbox--indicator-color[data-level="5"] {
  background-color: #FB0007; }

.bhbox--indicator-color[data-level="6"] {
  background-color: #B00004; }

.bhbox--indicator-color[data-level="7"] {
  background-color: #6C0001; }

.bhbox--indicator-color[data-level="8"] {
  background-color: #FFF; }

.bhbox--indicator-color[data-level="9"] {
  background-color: #FF0000; }

.bhbox--indicator-color[data-level="10"] {
  background-color: #FFFF66; }

.bhbox--indicator-color[data-level="11"] {
  background-color: #00B050; }

.bhbox--indicator-color[data-level="12"] {
  background-color: #FF0000; }

.bhbox--indicator-color[data-level="13"] {
  background-color: #FFCC00; }

.bhbox--indicator-color[data-level="14"] {
  background-color: #00B050; }

.bhbox--indicator-color[data-level="15"] {
  background-color: #8DB4E2; }

.bhbox--indicator-color[data-level="16"] {
  background-color: #0000FF; }

.detail-popover {
  position: absolute;
  background-color: white;
  z-index: 999;
  min-width: 300px;
  border-radius: 2px;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  padding: 0 8px 16px;
  display: flex;
  justify-content: center; }
  .detail-popover .indicator-arrow {
    position: absolute; }
    .detail-popover .indicator-arrow::after {
      content: "";
      border-right: 16px solid transparent;
      border-left: 16px solid transparent;
      border-bottom: 16px solid white;
      height: 0;
      width: 0;
      top: -16px;
      position: absolute; }
  .detail-popover .active-indicator {
    position: absolute;
    height: 24px;
    border: 3px solid white;
    border-radius: 2px;
    top: -33px;
    left: calc(50% - 14px);
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .detail-popover .bhbox--indicator-color {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black; }
  .detail-popover .bhbox--indicator-border {
    border-right: 1px solid black; }
  .detail-popover .inside-active-indicator {
    border: 3px solid black;
    border-radius: 2px;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .detail-popover .detail-popover--close {
    position: absolute;
    right: 0;
    top: 0;
    color: rgba(62, 39, 35, 0.54);
    cursor: pointer; }
  .detail-popover .detail-popover--item {
    width: calc(80%/10);
    float: left;
    color: rgba(62, 39, 35, 0.54); }
    .detail-popover .detail-popover--item .detail-popover--item-day {
      font-size: 10px;
      text-align: center; }
    .detail-popover .detail-popover--item .prediction-indicator:first-child {
      border: 2px solid black; }

.indicators-labels {
  padding: 0px 24px 24px 24px;
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 100%; }
  .indicators-labels .indicators-labels--item {
    position: relative;
    display: flex; }
    .indicators-labels .indicators-labels--item .label-color {
      height: 20px;
      width: 20px;
      float: left; }
    .indicators-labels .indicators-labels--item .label-text {
      color: #737373;
      float: left;
      font-size: 12px;
      margin-right: 12px;
      margin-left: 8px;
      flex-shrink: 4; }
      .indicators-labels .indicators-labels--item .label-text p {
        margin: 0;
        line-height: 1.2; }
  .indicators-labels .indicators-labels--item.level-0 .label-color {
    background-color: #0000FF;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-1 .label-color {
    background-color: #16A53F;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-2 .label-color {
    background-color: #FFFF54;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-3 .label-color {
    background-color: #FEC30A;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-4 .label-color {
    background-color: #D9560D;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-5 .label-color {
    background-color: #FB0007;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-6 .label-color {
    background-color: #B00004;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-7 .label-color {
    background-color: #6C0001;
    border: 1px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-8 .label-color {
    background-color: #FFFFFF;
    border: 3px solid #3e2723; }
  .indicators-labels .indicators-labels--item.level-9 .label-color {
    background-color: #FF0000; }
  .indicators-labels .indicators-labels--item.level-10 .label-color {
    background-color: #FFFF66; }
  .indicators-labels .indicators-labels--item.level-11 .label-color {
    background-color: #00B050; }
  .indicators-labels .indicators-labels--item.level-12 .label-color {
    background-color: #FF0000; }
  .indicators-labels .indicators-labels--item.level-13 .label-color {
    background-color: #FFCC00; }
  .indicators-labels .indicators-labels--item.level-14 .label-color {
    background-color: #00B050; }
  .indicators-labels .indicators-labels--item.level-15 .label-color {
    background-color: #8DB4E2; }
  .indicators-labels .indicators-labels--item.level-16 .label-color {
    background-color: #0000FF; }

.indicators-container {
  display: flex;
  flex-wrap: wrap;
}

.index-indicators-labels {
  padding: 8px 24px 24px 8px; }
  .index-indicators-labels .indicators-labels--item {
    position: relative;
    display: flex;
    margin-bottom: 10px; }
    .index-indicators-labels .indicators-labels--item .label-text-unit {
      color: rgba(62, 39, 35, 0.87);
      float: left;
      font-size: 12px;
      font-weight: 700;
      margin-right: 12px;
      flex-shrink: 4;
      text-align: center; }
      .index-indicators-labels .indicators-labels--item .label-text-unit p {
        margin: 0;
        line-height: 1.2; }

.bhbox-indicators-labels {
  padding: 0px 24px 24px 8px; }
  .bhbox-indicators-labels .indicators-labels--item {
    position: relative;
    display: flex; }
    .bhbox-indicators-labels .indicators-labels--item .label-text-unit {
      color: rgba(62, 39, 35, 0.87);
      float: left;
      font-size: 12px;
      font-weight: 700;
      margin-right: 12px;
      flex-shrink: 4;
      text-align: center; }
      .bhbox-indicators-labels .indicators-labels--item .label-text-unit p {
        margin: 0;
        line-height: 1.2; }

.bhbox--labels-data .year-label {
  width: 45px;
  font-size: 13px;
  text-align: center; }

.bhbox--labels-data .multiple-farms-label {
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid black; }

.bhbox-scroll.bhbox-scroll-multiple {
  margin-left: 20px; }

.bhbox-scroll {
  position: absolute;
  height: 56vh;
  width: 95%;
  margin-top: 45px; }
  .bhbox-scroll .ps__scrollbar-y-rail:hover, .bhbox-scroll .ps__scrollbar-y-rail:active, .bhbox-scroll .ps__scrollbar-y-railfocus {
    background-color: transparent !important;
    opacity: 0.6 !important; }
  .bhbox-scroll .ps__scrollbar-y-rail .ps__scrollbar-y {
    right: 0px;
    width: 4px !important; }
  .bhbox-scroll .ps__scrollbar-x-rail {
    height: 0px; }
    .bhbox-scroll .ps__scrollbar-x-rail .ps__scrollbar-y {
      height: 0px; }
    .bhbox-scroll .ps__scrollbar-x-rail:hover .ps__scrollbar-y, .bhbox-scroll .ps__scrollbar-x-rail:active .ps__scrollbar-y {
      height: 0px; }

@media (max-height: 570px) {
  .bhbox-scroll {
    height: 46vh; }
  }

@media (min-height: 571px) {
  .bhbox-scroll {
    height: 50vh; }
  }

@media (min-height: 671px) {
  .bhbox-scroll {
    height: 55vh; }
  }

@media (min-height: 700px) {
  .bhbox-scroll {
    height: 56vh; }
  }

@media (min-height: 800px) {
  .bhbox-scroll {
    height: 60vh; }
  }

@media (min-height: 900px) {
  .bhbox-scroll {
    height: 63vh; }
  }

@media (min-height: 1000px) {
  .bhbox-scroll {
    height: 65vh; }
  }

@media (min-height: 1080px) {
  .bhbox-scroll {
    height: 69vh; }
  }

@media (min-width: 1920px) {
  .bhbox-scroll {
    width: 1216px; } }

.atrmax-scroll {
  margin-top: 20px; }

.bhbox {
  color: rgba(62, 39, 35, 0.7);
  position: absolute;
  width: 95%; }
  .bhbox .bhbox--labels-months {
    display: flex;
    text-align: center;
    font-size: 13px; }
    .bhbox .bhbox--labels-months div {
      border-bottom: 1px solid black;
      border-left: 1px solid black;
      border-top: 1px solid black;
      width: calc(300%/38);
      line-height: 23px; }
    .bhbox .bhbox--labels-months #first-td {
      width: calc(200%/38);
      border-bottom: 0; }
  .bhbox .bhbox--labels-days {
    display: flex;
    font-size: 13px;
    text-align: center; }
    .bhbox .bhbox--labels-days .years-label {
      width: calc(200%/38);
      font-size: 13px; }
      .bhbox .bhbox--labels-days .years-label label {
        position: absolute;
        top: 15px;
        right: 95.5%;
        color: rgba(62, 39, 35, 0.7); }
    .bhbox .bhbox--labels-days div {
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      width: calc(100%/38); }
  .bhbox .bhbox--labels-days-multiple .multiple-farms-up {
    width: calc(200%/38); }
  .bhbox .bhbox--labels-days-multiple .cad-label {
    width: calc(300%/38); }

@media (min-width: 1920px) {
  .bhbox {
    width: 1216px; } }

.bhbox--labels-data {
  display: flex; }
  .bhbox--labels-data .year-label {
    width: calc(200%/38);
    border-left: 1px solid black;
    border-bottom: 1px solid black; }
  .bhbox--labels-data .bhbox--indicator {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    width: calc(100%/38); }
  .bhbox--labels-data .first-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid black; }
  .bhbox--labels-data .middle-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 2px solid black; }
  .bhbox--labels-data .last-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    border-right: 2px solid black; }

.first--indicator-prediction {
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important; }

.second--indicator-prediction {
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important; }

.third--indicator-prediction {
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important;
  border-right: 2px solid black !important; }

.first--indicator-prediction-detail {
  border-top: 3px solid black !important;
  border-bottom: 3px solid black !important;
  border-left: 3px solid black !important; }

.second--indicator-prediction-detail {
  border-top: 3px solid black !important;
  border-bottom: 3px solid black !important; }

.third--indicator-prediction-detail {
  border-top: 3px solid black !important;
  border-bottom: 3px solid black !important;
  border-right: 3px solid black !important; }

.bhbox--labels-data-multiple .bhbox--indicator {
  width: calc(100%/41); }

.bhbox--labels-data-multiple .multiple-farms-label-initials {
  width: calc(200%/41);
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 13px;
  text-align: center; }

.bhbox--labels-data-multiple .multiple-farms-label-cad {
  width: calc(300%/41);
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 13px;
  text-align: center; }

.index--labels-data-multiple .bhbox--indicator {
  width: calc(100%/37); }

.index--labels-data-multiple .multiple-farms-label-initials {
  width: calc(200%/37);
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 13px;
  text-align: center; }

.multiple-farms-cad {
  float: left;
  width: calc(300%/41);
  height: 45px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  text-align: center; }
  .multiple-farms-cad label {
    position: relative;
    top: 25%; }

#multiple-farms-first-td {
  float: left;
  width: calc(202%/41);
  height: 45px;
  border: 1px solid black;
  text-align: center; }
  #multiple-farms-first-td label {
    position: relative;
    top: 25%; }

#multiple-farms-first-td-index {
  float: left;
  width: calc(200%/38);
  height: 45px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  text-align: center; }
  #multiple-farms-first-td-index label {
    position: relative;
    top: 25%; }

.bhbox-multiple {
  margin-left: 20px; }

.scrollbar-container.index-scroll-multiple {
  margin-left: 20px; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data .bhbox--indicator {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    width: calc(100%/38); }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data .first-prediction-indicator div {
    border-top: 0px;
    border-bottom: 0px;
    border-left: 2px solid black; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data .middle-prediction-indicator div {
    border-top: 0px;
    border-bottom: 0px; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data .last-prediction-indicator div {
    border-top: 0px;
    border-bottom: 0px;
    border-right: 2px solid black; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data:nth-last-child(3) .first-prediction-indicator div {
    border-top: 0px;
    border-bottom: 2px solid black;
    border-left: 2px solid black; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data:nth-last-child(3) .middle-prediction-indicator div {
    border-top: 0px;
    border-bottom: 2px solid black; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data:nth-last-child(3) .last-prediction-indicator div {
    border-top: 0px;
    border-bottom: 2px solid black;
    border-right: 2px solid black; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data:first-child .first-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 0px;
    border-left: 2px solid black; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data:first-child .middle-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 0px; }
  .scrollbar-container.index-scroll-multiple .bhbox--labels-data:first-child .last-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 0px;
    border-right: 2px solid black; }

.months-labels-multiple {
  width: calc(400%/38) !important; }

.days-labels-multiple {
  width: calc(200%/38) !important; }

.months-bhbox-labels-multiple {
    width: calc(300%/36) !important; }

.days-bhbox-labels-multiple {
      width: calc(100%/36) !important; }
.index-scroll {
  position: absolute;
  height: 40vh;
  width: 95%;
  margin-top: 45.5px; }
  .index-scroll .ps__scrollbar-x-rail {
    height: 0px; }
    .index-scroll .ps__scrollbar-x-rail .ps__scrollbar-y {
      height: 0px; }
    .index-scroll .ps__scrollbar-x-rail:hover .ps__scrollbar-y, .index-scroll .ps__scrollbar-x-rail:active .ps__scrollbar-y {
      height: 0px; }

@media (min-width: 1920px) {
  .index-scroll {
    width: 1216px; } }

@media (min-width: 1600px) {
  .index-scroll {
    height: 63vh; }
  }

.highcharts-tooltip > span {
  background: white;
  border: 1px solid black;
  padding: 8px;
  font-size: 15px; }
