.upload-info-container {
  text-align: left;
  padding: 2% 3% 0%;
  line-height: 1.2; }
  .upload-info-container .upload-page-title {
    font-style: italic;
    font-size: 22px;
    font-weight: 700;
    color: green; }
  .upload-info-container .upload-page-info {
    font-weight: 600;
    font-size: 12px; }
  .upload-info-container .upload-info-row {
    height: 90%;
    margin-bottom: 0 !important; }
    .upload-info-container .upload-info-row .upload-exp {
      height: 100%;
      font-size: 12px; }
      .upload-info-container .upload-info-row .upload-exp p {
        text-align: start; }
      .upload-info-container .upload-info-row .upload-exp ol {
        text-align: start;
        list-style: none;
        counter-reset: custom;
        padding-left: 1%; }
        .upload-info-container .upload-info-row .upload-exp ol li {
          counter-increment: custom; }
          .upload-info-container .upload-info-row .upload-exp ol li::before {
            content: counter(custom) ". ";
            color: green;
            font-weight: 600; }
      .upload-info-container .upload-info-row .upload-exp ul {
        text-align: start;
        list-style-type: disc;
        counter-reset: custom;
        padding-left: 1%; }
        .upload-info-container .upload-info-row .upload-exp ul li::before {
          content: "\2022";
          color: green;
          font-weight: bold;
          display: inline-block;
          width: 1em; }
        .upload-info-container .upload-info-row .upload-exp ul li .tab {
          display: inline-block;
          width: 2.6em; }
      .upload-info-container .upload-info-row .upload-exp .title {
        font-size: 18px;
        line-height: 1.4; }
      .upload-info-container .upload-info-row .upload-exp .green-bold {
        color: green;
        font-weight: 600;
        line-height: 1; }
      .upload-info-container .upload-info-row .upload-exp .side-panel {
        padding: 2px 15px;
        margin-top: 17px;
        background-color: #dbd8d7;
        font-size: 11px;
        margin-right: 12.5%; }
      .upload-info-container .upload-info-row .upload-exp .footer {
        margin-top: 1.5%;
        font-size: 11px;
        font-weight: 600;
        text-align: center; }
      .upload-info-container .upload-info-row .upload-exp .upload-icon.material-icons {
        color: #6aa426;
        font-size: 36px;
        margin-left: 50; }
  .upload-info-container .upload-form {
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: #dbd8d7;
    padding: 10px; }
    .upload-info-container .upload-form .upload-form-grid {
      height: 100%; }
      .upload-info-container .upload-form .upload-form-grid .vertical-line {
        border-right: 0.1em solid black;
        height: 100%;
        max-width: 0.1%; }
      .upload-info-container .upload-form .upload-form-grid .point-select-form {
        align-items: center; }
        .upload-info-container .upload-form .upload-form-grid .point-select-form .point-select {
          border-bottom: 2px solid #6aa426;
          font: inherit;
          font-size: 14px; }
      .upload-info-container .upload-form .upload-form-grid .upload-user-data {
        height: 100%;
        align-items: center;
        margin-top: 10px; }
        .upload-info-container .upload-form .upload-form-grid .upload-user-data .input-file-name {
          font: inherit;
          font-size: 14px; }
        .upload-info-container .upload-form .upload-form-grid .upload-user-data .upload-context .input-user-data {
          display: none; }
        .upload-info-container .upload-form .upload-form-grid .upload-user-data .upload-context .upload-icon {
          color: #6aa426;
          font-size: 36px; }
