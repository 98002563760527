.last-key {
  border-right: 1px solid black; }

.fire--indicator-color {
  height: 20px;
  cursor: pointer; }

.fire--indicator-color[data-level="0"] {
  background-color: #FFFF54; }

.fire--indicator-color[data-level="1"] {
  background-color: #FEC30A; }

.fire--indicator-color[data-level="2"] {
  background-color: #ff8a4a; }

.fire--indicator-color[data-level="3"] {
  background-color: #ff5e00; }

.fire--indicator-color[data-level="4"] {
  background-color: #ff0000; }

.fire--indicator-color[data-level="5"] {
  background-color: #ffffff; }

.detail-popover {
  position: absolute;
  background-color: white;
  z-index: 999;
  min-width: 300px;
  border-radius: 2px;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  padding: 0 8px 16px;
  display: flex;
  justify-content: center; }
  .detail-popover .indicator-arrow {
    position: absolute; }
    .detail-popover .indicator-arrow::after {
      content: "";
      border-right: 16px solid transparent;
      border-left: 16px solid transparent;
      border-bottom: 16px solid white;
      height: 0;
      width: 0;
      top: -16px;
      position: absolute; }
  .detail-popover .active-indicator {
    position: absolute;
    height: 24px;
    border: 3px solid white;
    border-radius: 2px;
    top: -33px;
    left: calc(50% - 14px);
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .detail-popover .fire--indicator-color {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black; }
  .detail-popover .fire--indicator-border {
    border-right: 1px solid black; }
  .detail-popover .inside-active-indicator {
    border: 3px solid black;
    border-radius: 2px;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .detail-popover .detail-popover--close {
    position: absolute;
    right: 0;
    top: 0;
    color: rgba(62, 39, 35, 0.54);
    cursor: pointer; }
  .detail-popover .detail-popover--item {
    width: calc(80%/10);
    float: left;
    color: rgba(62, 39, 35, 0.54); }
    .detail-popover .detail-popover--item .detail-popover--item-day {
      font-size: 10px;
      text-align: center; }
    .detail-popover .detail-popover--item .prediction-indicator:first-child {
      border: 2px solid black; }

.fire-indicators-labels {
  padding: 0px 24px 24px 24px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  width: 100%; }
  .fire-indicators-labels .indicators-labels--item {
    position: relative;
    display: flex; }
    .fire-indicators-labels .indicators-labels--item .label-color {
      height: 20px;
      width: 20px;
      float: left; }
    .fire-indicators-labels .indicators-labels--item .label-text {
      color: #737373;
      float: left;
      font-size: 12px;
      margin-right: 12px;
      margin-left: 8px;
      flex-shrink: 4; }
      .fire-indicators-labels .indicators-labels--item .label-text p {
        margin: 0;
        line-height: 1.2; }
  .fire-indicators-labels .indicators-labels--item.level-0 .label-color {
    background-color: #FFFF54;
    border: 1px solid #3e2723; }
  .fire-indicators-labels .indicators-labels--item.level-1 .label-color {
    background-color: #FEC30A;
    border: 1px solid #3e2723; }
  .fire-indicators-labels .indicators-labels--item.level-2 .label-color {
    background-color: #ff8a4a;
    border: 1px solid #3e2723; }
  .fire-indicators-labels .indicators-labels--item.level-3 .label-color {
    background-color: #ff5e00;
    border: 1px solid #3e2723; }
  .fire-indicators-labels .indicators-labels--item.level-4 .label-color {
    background-color: #ff0000;
    border: 1px solid #3e2723; }
  .fire-indicators-labels .indicators-labels--item.level-5 .label-color {
    background-color: #FFFFFF;
    border: 3px solid #3e2723; }

.index-indicators-labels {
  padding: 8px 24px 24px 8px; }
  .index-indicators-labels .indicators-labels--item {
    position: relative;
    display: flex;
    margin-bottom: 10px; }
    .index-indicators-labels .indicators-labels--item .label-text-unit {
      color: rgba(62, 39, 35, 0.87);
      float: left;
      font-size: 12px;
      font-weight: 700;
      margin-right: 12px;
      flex-shrink: 4;
      text-align: center; }
      .index-indicators-labels .indicators-labels--item .label-text-unit p {
        margin: 0;
        line-height: 1.2; }

.fire-indicators-labels {
  padding: 0px 24px 24px 8px;
  bottom: 20px; }
  .fire-indicators-labels .indicators-labels--item {
    position: relative;
    display: flex; }
    .fire-indicators-labels .indicators-labels--item .label-text-unit {
      color: rgba(62, 39, 35, 0.87);
      float: left;
      font-size: 12px;
      font-weight: 700;
      margin-right: 12px;
      flex-shrink: 4;
      text-align: center; }
      .fire-indicators-labels .indicators-labels--item .label-text-unit p {
        margin: 0;
        line-height: 1.2; }

.fire--labels-data .year-label {
  width: 45px;
  font-size: 13px;
  text-align: center; }

.fire--labels-data .multiple-farms-label {
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid black; }

.fire-scroll {
  position: absolute;
  height: 56vh;
  width: 95%;
  margin-top: 45px; }
  .fire-scroll .ps__scrollbar-y-rail:hover, .fire-scroll .ps__scrollbar-y-rail:active, .fire-scroll .ps__scrollbar-y-railfocus {
    background-color: transparent !important;
    opacity: 0.6 !important; }
  .fire-scroll .ps__scrollbar-y-rail .ps__scrollbar-y {
    right: 0px;
    width: 4px !important; }
  .fire-scroll .ps__scrollbar-x-rail {
    height: 0px; }
    .fire-scroll .ps__scrollbar-x-rail .ps__scrollbar-y {
      height: 0px; }
    .fire-scroll .ps__scrollbar-x-rail:hover .ps__scrollbar-y, .fire-scroll .ps__scrollbar-x-rail:active .ps__scrollbar-y {
      height: 0px; }

@media (max-height: 570px) {
  .fire-scroll {
    height: 46vh; } }

@media (min-height: 571px) {
  .fire-scroll {
    height: 50vh; } }

@media (min-height: 671px) {
  .fire-scroll {
    height: 55vh; } }

@media (min-height: 700px) {
  .fire-scroll {
    height: 56vh; } }

@media (min-height: 800px) {
  .fire-scroll {
    height: 60vh; } }

@media (min-height: 900px) {
  .fire-scroll {
    height: 63vh; } }

@media (min-height: 1000px) {
  .fire-scroll {
    height: 65vh; } }

@media (min-height: 1080px) {
  .fire-scroll {
    height: 69vh; } }

.atrmax-scroll {
  margin-top: 20px; }

.fire {
  color: rgba(62, 39, 35, 0.7);
  position: absolute;
  width: 95%; }
  .fire .fire--labels-months {
    display: flex;
    text-align: center;
    font-size: 13px; }
    .fire .fire--labels-months div {
      border-bottom: 1px solid black;
      border-left: 1px solid black;
      border-top: 1px solid black;
      width: calc(300%/38);
      line-height: 23px; }
    .fire .fire--labels-months #first-td {
      width: calc(200%/38);
      border-bottom: 0; }
  .fire .fire--labels-days {
    display: flex;
    font-size: 13px;
    text-align: center; }
    .fire .fire--labels-days .years-label {
      width: calc(200%/38);
      font-size: 13px; }
      .fire .fire--labels-days .years-label label {
        position: absolute;
        top: 15px;
        right: 95.5%;
        color: rgba(62, 39, 35, 0.7); }
    .fire .fire--labels-days div {
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      width: calc(100%/38); }
  .fire .fire--labels-days-multiple .multiple-farms-up {
    width: calc(200%/38); }
  .fire .fire--labels-days-multiple .cad-label {
    width: calc(300%/38); }

.fire--labels-data {
  display: flex; }
  .fire--labels-data .year-label {
    width: calc(200%/38);
    border-left: 1px solid black;
    border-bottom: 1px solid black; }
  .fire--labels-data .fire--indicator {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    width: calc(100%/38); }
  .fire--labels-data .first-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid black; }
  .fire--labels-data .middle-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 2px solid black; }
  .fire--labels-data .last-prediction-indicator div {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    border-right: 2px solid black; }

.first--indicator-prediction {
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important; }

.second--indicator-prediction {
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important; }

.third--indicator-prediction {
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important;
  border-right: 2px solid black !important; }

.first--indicator-prediction-detail {
  border-top: 3px solid black !important;
  border-bottom: 3px solid black !important;
  border-left: 3px solid black !important; }

.second--indicator-prediction-detail {
  border-top: 3px solid black !important;
  border-bottom: 3px solid black !important; }

.third--indicator-prediction-detail {
  border-top: 3px solid black !important;
  border-bottom: 3px solid black !important;
  border-right: 3px solid black !important; }

.fire--labels-data-multiple .fire--indicator {
  width: calc(100%/41); }

.fire--labels-data-multiple .multiple-farms-label-initials {
  width: calc(200%/41);
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 13px;
  text-align: center; }

.fire--labels-data-multiple .multiple-farms-label-cad {
  width: calc(300%/41);
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 13px;
  text-align: center; }

.index--labels-data-multiple .fire--indicator {
  width: calc(100%/37); }

.index--labels-data-multiple .multiple-farms-label-initials {
  width: calc(200%/37);
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 13px;
  text-align: center; }

.multiple-farms-cad {
  float: left;
  width: calc(300%/41);
  height: 45px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  text-align: center; }
  .multiple-farms-cad label {
    position: relative;
    top: 25%; }

#multiple-farms-first-td {
  float: left;
  width: calc(202%/41);
  height: 45px;
  border: 1px solid black;
  text-align: center; }
  #multiple-farms-first-td label {
    position: relative;
    top: 25%; }

#multiple-farms-first-td-index {
  float: left;
  width: calc(200%/38);
  height: 45px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  text-align: center; }
  #multiple-farms-first-td-index label {
    position: relative;
    top: 25%; }

.fire-multiple {
  margin-left: 20px; }

.fire-scroll-multiple {
  margin-left: 20px; }

.months-labels-multiple {
  width: calc(400%/38) !important; }

.days-labels-multiple {
  width: calc(200%/38) !important; }
