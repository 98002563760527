.api-error-boundarie {
  position: absolute;
  text-align: center;
  height: 50%;
  width: 100%;
  top: 25%;
  color: rgba(62, 39, 35, 0.87); }
  .api-error-boundarie .api-error-icon {
    font-size: 36px;
    color: rgba(62, 39, 35, 0.87); }
