.admin-sidebar {
  background-color: #6aa426;
  width: 25%;
  height: 100vh;
  position: fixed;
}
.admin-sidebar .admin-sidebar-title {
  text-align: center;
  color: white;
}
.admin-sidebar .admin-sidebar-item {
  padding: 16px;
}
.admin-sidebar .admin-sidebar-item a {
  display: flex;
  align-items: center;
  color: white;
  background-color: inherit;
  font-weight: 500;
  border: 0;
}
.admin-sidebar .admin-sidebar-item a i {
  float: left;
  margin-right: 16px;
}
.admin-sidebar .admin-sidebar-item a span {
  margin-left: 16px;
}
.admin-sidebar .admin-sidebar-item .custom-icon {
  height: 28px;
}

.admin-information {
  position: absolute;
  right: 0;
  width: 75%;
  height: 100vh;
}
.admin-information .dropdown-margin-top {
  margin-top: 24px;
}
.admin-information .row-lat-lon-input {
  margin-top: 24px;
}
.admin-information .admin-home-page {
  padding: 120px;
}
.admin-information .variables-select {
  padding: 16px;
  margin-top: 8px;
}
.admin-information .variables-select h6 {
  margin-bottom: 16px;
}
.admin-information .variables-select label {
  font-size: 16px;
  color: black;
}
.admin-information .variables-select label .rc-checkbox .rc-checkbox-inner {
  border-color: #3dbcf6;
}
.admin-information .variables-select .prodtype-select {
  width: 100%;
}
.admin-information .enos-list-container {
  padding: 16px;
  margin-bottom: 0;
}
.admin-information .enos-list-container .enos-list-container-title {
  text-align: center;
}
.admin-information
  .enos-list-container
  .enos-list-container-title
  .admin-add-enos {
  width: 100px;
}
.admin-information
  .enos-list-container
  .enos-list-container-title
  .admin-add-enos
  span {
  margin-left: 8px;
  font-size: 0.8rem;
  color: #9e9e9e;
}
.admin-information .enos-form-container {
  padding: 16px;
  margin-bottom: 0;
}
.admin-information .enos-form-container .enos-form-container-title {
  text-align: center;
  margin-bottom: 32px;
}
.admin-information .enos-form-container .button-row {
  margin-top: 32px;
  float: right;
}
.admin-information .data-download-container {
  padding: 16px;
  margin-bottom: 0;
}
.admin-information .data-download-container .productivity-form {
  height: 400px;
  display: grid;
}
.admin-information .data-download-container .productivity-form .upper-forms {
  grid-template-rows: calc(100% / 3) calc(100% / 3) calc(100% / 3);
  align-items: start;
}
.admin-information .data-download-container .productivity-form .lower-forms {
  display: grid;
  align-items: end;
}
.admin-information .data-download-container .table-container {
  margin: 12px 0;
  position: absolute;
  top: 10%;
  right: 10%;
}
.admin-information .data-download-container .table-container .ReactTable {
  height: 300px;
}
.admin-information .data-download-container .button-row {
  float: right;
  margin-bottom: 0;
}
.admin-information .data-download-container .dataset-select {
  width: 100%;
}
.admin-information .data-download-container .lat-lon-input {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.admin-information .data-download-container .lat-lon-input .input-field {
  margin-top: 0;
}
.admin-information
  .data-download-container
  .lat-lon-input
  .input-field
  .input-field
  label:not(.label-icon).active {
  transform: translateY(-100%);
}
.admin-information .data-download-container .product-form {
  display: grid;
  margin-top: 8px;
}
.admin-information
  .data-download-container
  .product-form
  .prod-months-dropdown {
  width: 90%;
}
.admin-information .data-download-container .product-form .label {
  width: 100%;
  margin: 0;
  margin-top: 8px;
  margin-bottom: -8px;
}
.admin-information .data-download-container .product-form .margin-left-10 {
  margin-left: 10%;
}
.admin-information .data-download-container .product-form .text-align-end {
  text-align: end;
}
.admin-information .data-download-container .product-form .helper {
  font-size: 0.8rem;
  margin: 0;
}
.admin-information
  .data-download-container
  .product-form
  .btn.wave-light.green {
  margin-top: 1.5em;
  font-size: 12px;
}
.admin-information .notification-container {
  padding: 16px 80px 16px 24px;
  margin-bottom: 0;
}
.admin-information .notification-container .add-notification-button-container {
  margin: 32px 0px;
}
.admin-information .notification-container .notification-form-container {
  margin: 32px 0px;
}
.admin-information
  .notification-container
  .notification-form-container
  .margin-16 {
  margin-top: 16px;
}
.admin-information
  .notification-container
  .notification-form-container
  .notification-form-text-field {
  border-radius: 4px;
  padding: 8px 16px;
}
.admin-information
  .notification-container
  .notification-form-container
  .notification-form-input {
  margin: 0;
  border-bottom-style: none;
}
.admin-information
  .notification-container
  .notification-form-container
  .notification-form-input:focus {
  border-bottom-style: none !important;
  box-shadow: none !important;
}
.admin-information .notification-container .embed-content {
  width: 640px;
  height: 360px;
}
.admin-information .users-list-container {
  padding: 16px;
}
.admin-information .users-list-container .users-list-container-title {
  text-align: center;
}
.admin-information .users-form-container {
  padding: 16px;
}
.admin-information .users-form-container .button {
  background-color: #f2f2f2;
  color: black;
}
.admin-information .users-form-container .green-button {
  background-color: #6aa426;
  color: white;
}
.admin-information .users-form-container .green-button.api {
  margin-top: 32px;
}
.admin-information .users-form-container .checkbox {
  color: #4caf4f;
}
.admin-information .users-form-container .users-form-container-title {
  margin-top: 1%;
  font-size: 1.2em;
  align-items: center;
}
.admin-information
  .users-form-container
  .users-form-container-title
  .admin-edit-user
  span {
  margin-left: 5px;
  font-size: 0.8rem;
  color: #9e9e9e;
}
.admin-information .users-form-container .info-form {
  padding-top: 2px;
}
.admin-information .users-form-container .info-form input {
  margin: 0px;
}
.admin-information .users-form-container .info-form input:disabled {
  color: black;
}
.admin-information
  .users-form-container
  .info-form
  .user-address
  .user-status
  .admin-activate-user {
  position: relative;
  width: 25%;
  padding-top: 24px;
}
.admin-information
  .users-form-container
  .info-form
  .user-address
  .user-status
  .admin-activate-user
  span {
  margin-left: 5px;
  font-size: 0.8rem;
  color: #9e9e9e;
}
.admin-information .users-form-container .info-form .button-row {
  float: right;
  margin-top: 32px;
}

.access-control,
.api-control,
.plan-control {
  border-top: 1px solid;
  margin-top: 0.5%;
  height: 100%;
}
.access-control .api-key,
.api-control .api-key,
.plan-control .api-key {
  margin-left: 8px;
}
.access-control .api-key .api-label,
.api-control .api-key .api-label,
.plan-control .api-key .api-label {
  font-size: 0.9rem;
  color: #9e9e9e;
}
.access-control .api-key input::-webkit-outer-spin-button,
.access-control .api-key input::-webkit-outer-spin-button,
.access-control .api-key input[type="number"],
.api-control .api-key input::-webkit-outer-spin-button,
.api-control .api-key input::-webkit-outer-spin-button,
.api-control .api-key input[type="number"],
.plan-control .api-key input::-webkit-outer-spin-button,
.plan-control .api-key input::-webkit-outer-spin-button,
.plan-control .api-key input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}
.access-control .title-row,
.api-control .title-row,
.plan-control .title-row {
  margin: 16px 0;
}
.access-control .management-container,
.api-control .management-container,
.plan-control .management-container {
  width: 100%;
}
.access-control .management-container .product-container,
.api-control .management-container .product-container,
.plan-control .management-container .product-container {
  background-color: #f2f2f2;
  height: 100%;
  width: 100%;
  padding: 2px;
}
.access-control .management-container .product-container .product-title,
.api-control .management-container .product-container .product-title,
.plan-control .management-container .product-container .product-title {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid;
  height: 30px;
  padding: 3px;
  margin-bottom: 0.5%;
  font-size: 14px;
  line-height: 1.5rem;
}
.access-control .management-container .product-container .product-list,
.api-control .management-container .product-container .product-list,
.plan-control .management-container .product-container .product-list {
  height: 42vh;
  padding-top: 2%;
  align-content: baseline;
}
.access-control
  .management-container
  .product-container
  .product-list
  .product-item,
.api-control
  .management-container
  .product-container
  .product-list
  .product-item,
.plan-control
  .management-container
  .product-container
  .product-list
  .product-item {
  height: 65px;
  width: auto;
  -webkit-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.access-control
  .management-container
  .product-container
  .product-list
  .product-button,
.api-control
  .management-container
  .product-container
  .product-list
  .product-button,
.plan-control
  .management-container
  .product-container
  .product-list
  .product-button {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}
.access-control
  .management-container
  .product-container
  .product-list
  .product-button.selected,
.api-control
  .management-container
  .product-container
  .product-list
  .product-button.selected,
.plan-control
  .management-container
  .product-container
  .product-list
  .product-button.selected {
  width: 100%;
  background-color: #69a42671;
}
.access-control .point-container,
.api-control .point-container,
.plan-control .point-container {
  background-color: #f2f2f2;
  padding: 2px;
}
.access-control .point-container .point-title,
.api-control .point-container .point-title,
.plan-control .point-container .point-title {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid;
  height: 30px;
  padding: 3px;
  margin-bottom: 0.5%;
  font-size: 14px;
  line-height: 1.5rem;
}
.access-control .point-container .point-search input,
.api-control .point-container .point-search input,
.plan-control .point-container .point-search input {
  text-align: center;
  height: 1.2rem;
  font-size: 0.9rem;
  width: 100%;
  margin: 0;
}
.access-control .point-container .point-search .icon-button,
.api-control .point-container .point-search .icon-button,
.plan-control .point-container .point-search .icon-button {
  float: right;
  min-width: 8px;
  padding: 1px;
}
.access-control .point-container .point-search .icon-button:active,
.api-control .point-container .point-search .icon-button:active,
.plan-control .point-container .point-search .icon-button:active {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .point-container .point-search .icon-button:focus,
.api-control .point-container .point-search .icon-button:focus,
.plan-control .point-container .point-search .icon-button:focus {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .point-container .point-button,
.api-control .point-container .point-button,
.plan-control .point-container .point-button {
  width: 100%;
  padding: 5px;
  font-size: 14px;
}
.access-control .point-container .point-button:active,
.api-control .point-container .point-button:active,
.plan-control .point-container .point-button:active {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .point-container .point-button:focus,
.api-control .point-container .point-button:focus,
.plan-control .point-container .point-button:focus {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .point-container .point-list,
.api-control .point-container .point-list,
.plan-control .point-container .point-list {
  padding: 1%;
  height: 42vh;
  align-content: baseline;
  text-align: center;
}
.access-control .selected-point-container,
.api-control .selected-point-container,
.plan-control .selected-point-container {
  background-color: #f2f2f2;
  padding: 2px;
}
.access-control .selected-point-container .selected-icon-button,
.api-control .selected-point-container .selected-icon-button,
.plan-control .selected-point-container .selected-icon-button {
  float: right;
  min-width: 8px;
  padding: 1px;
}
.access-control .selected-point-container .selected-icon-button:active,
.api-control .selected-point-container .selected-icon-button:active,
.plan-control .selected-point-container .selected-icon-button:active {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .selected-point-container .selected-icon-button:focus,
.api-control .selected-point-container .selected-icon-button:focus,
.plan-control .selected-point-container .selected-icon-button:focus {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .selected-point-container .selected-point-button,
.api-control .selected-point-container .selected-point-button,
.plan-control .selected-point-container .selected-point-button {
  width: 100%;
  padding: 5px;
  font-size: 14px;
}
.access-control .selected-point-container .selected-point-button:active,
.api-control .selected-point-container .selected-point-button:active,
.plan-control .selected-point-container .selected-point-button:active {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .selected-point-container .selected-point-button:focus,
.api-control .selected-point-container .selected-point-button:focus,
.plan-control .selected-point-container .selected-point-button:focus {
  background-color: rgba(0, 0, 0, 0);
}
.access-control .selected-point-container .authorized.selected-point-button,
.api-control .selected-point-container .authorized.selected-point-button,
.plan-control .selected-point-container .authorized.selected-point-button {
  background-color: #69a42671;
}
.access-control .selected-point-container .selected-point-title,
.api-control .selected-point-container .selected-point-title,
.plan-control .selected-point-container .selected-point-title {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid;
  height: 30px;
  padding: 3px;
  margin-bottom: 0.5%;
  font-size: 14px;
  line-height: 1.5rem;
}
.access-control .selected-point-container .selected-point-search input,
.api-control .selected-point-container .selected-point-search input,
.plan-control .selected-point-container .selected-point-search input {
  text-align: center;
  height: 1.2rem;
  font-size: 0.9rem;
  width: 100%;
  margin: 0;
}
.access-control .selected-point-container .selected-point-search .counter,
.api-control .selected-point-container .selected-point-search .counter,
.plan-control .selected-point-container .selected-point-search .counter {
  font-size: 2vh;
  margin-right: 10%;
}
.access-control
  .selected-point-container
  .selected-point-search
  .counter.max-selected,
.api-control
  .selected-point-container
  .selected-point-search
  .counter.max-selected,
.plan-control
  .selected-point-container
  .selected-point-search
  .counter.max-selected {
  font-size: 2vh;
  color: red;
}
.access-control .selected-point-container .selected-point-list,
.api-control .selected-point-container .selected-point-list,
.plan-control .selected-point-container .selected-point-list {
  padding: 1%;
  height: 42vh;
  align-content: baseline;
  text-align: center;
}
.access-control .maximum-points-container,
.api-control .maximum-points-container,
.plan-control .maximum-points-container {
  height: 40px;
  margin-top: 1%;
  margin-bottom: 1%;
  background-color: #f2f2f2;
  padding: 1%;
  font-size: 14px;
}
.access-control .maximum-points-container input,
.api-control .maximum-points-container input,
.plan-control .maximum-points-container input {
  text-align: center;
  height: 1.2rem;
  width: 40px;
  margin: 0;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
.access-control .maximum-points-container button,
.api-control .maximum-points-container button,
.plan-control .maximum-points-container button {
  padding: 0;
  margin-left: 40%;
  background-color: #6aa426;
  color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  font-size: 12px;
}
.access-control .user-manage-button,
.api-control .user-manage-button,
.plan-control .user-manage-button {
  color: black;
  align-items: center;
}
.access-control .user-manage-button .helper,
.api-control .user-manage-button .helper,
.plan-control .user-manage-button .helper {
  margin: 0px;
  margin-top: -10px;
  font-size: 10px;
}
.access-control .point-select-form,
.api-control .point-select-form,
.plan-control .point-select-form {
  text-align: center;
}
.access-control .point-select-form .point-select,
.api-control .point-select-form .point-select,
.plan-control .point-select-form .point-select {
  border-bottom: 2px solid #4caf4f;
  font: inherit;
  font-size: 14px;
  width: 200px;
}
.access-control .productivity-region,
.api-control .productivity-region,
.plan-control .productivity-region {
  margin-top: 2%;
}
.access-control .productivity-region .title-row,
.api-control .productivity-region .title-row,
.plan-control .productivity-region .title-row {
  padding-top: 1%;
}
.access-control .productivity-region .checkbox-region,
.api-control .productivity-region .checkbox-region,
.plan-control .productivity-region .checkbox-region {
  margin-bottom: 10px;
}
.access-control .plan-header .input-field,
.api-control .plan-header .input-field,
.plan-control .plan-header .input-field {
  margin: 0;
}
.access-control .plan-label,
.api-control .plan-label,
.plan-control .plan-label {
  font-size: 0.9rem;
  color: #9e9e9e;
}
.access-control .plan-input .plan-label,
.api-control .plan-input .plan-label,
.plan-control .plan-input .plan-label {
  margin-left: 0px;
}
.access-control .plan-input .input-field,
.api-control .plan-input .input-field,
.plan-control .plan-input .input-field {
  margin-top: 0px;
  width: 100px;
}
.access-control input:disabled,
.api-control input:disabled,
.plan-control input:disabled {
  color: black;
}
.access-control button:disabled,
.api-control button:disabled,
.plan-control button:disabled {
  background-color: #cccccc !important;
}

.admin-add-enos {
  width: 100px;
}
.admin-add-enos span {
  margin-left: 8px;
  font-size: 0.8rem;
  color: #9e9e9e;
}
