header.flowering-header {
  height: 64px;
  padding: 0px 24px; }
  header.flowering-header .flowering-options-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    header.flowering-header .flowering-options-container .flowering-dropdowns-container {
      display: flex; }
      header.flowering-header .flowering-options-container .flowering-dropdowns-container .flowering-prediction-container {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-right: 8px; }

.flowering-scroll {
  height: 74vh;
  position: absolute;
  width: 100%;
  margin-top: 8px; }
  .flowering-scroll .flowering-chart-container {
    position: absolute;
    width: 98%;
    height: 400px;
    margin-top: -14px;
    padding-left: 24px; }
    .flowering-scroll .flowering-chart-container .flowering-chart {
      height: 430px;
      width: auto;
      padding-top: 10px; }
    .flowering-scroll .flowering-chart-container .loading-screen-flowering {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      text-align: center;
      background-color: rgba(62, 39, 35, 0.1);
      height: 400px;
      margin-left: 24px;
      margin-right: 24px;
      font-weight: 400;
      color: rgba(62, 39, 35, 0.54);
      font-size: 16px; }
    @media (min-height: 900px) {
      .flowering-scroll .flowering-chart-container .flowering-chart {
        height: 680px; }
      .flowering-scroll .flowering-chart-container .loading-screen-flowering {
        height: 630px; } }
  .flowering-scroll .inductive-legend {
    position: absolute;
    margin-top: 395px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    font-size: 16px; }
    .flowering-scroll .inductive-legend .inductive-period {
      padding: 4px;
      margin-right: 8px; }
      .flowering-scroll .inductive-legend .inductive-period .arrow {
        display: inline-block;
        transform: rotate(90deg);
        font-size: 16px; }
    .flowering-scroll .inductive-legend .decision-period {
      padding: 4px;
      margin-left: 8px; }
      .flowering-scroll .inductive-legend .decision-period .square {
        height: 16px;
        width: 16px;
        border: 1px dashed;
        display: inline-block;
        margin-right: 8px;
        vertical-align: text-bottom; }
  @media (min-height: 900px) {
    .flowering-scroll .inductive-legend {
      margin-top: 650px; } }
  .flowering-scroll .flowering-legend {
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: 450px;
    width: 100%;
    height: 110px;
    padding: 8px; }
    .flowering-scroll .flowering-legend .action-table {
      width: 60%;
      display: grid;
      padding: 2px;
      grid-template-columns: 20% 30% 50%; }
      .flowering-scroll .flowering-legend .action-table .header {
        background-color: gainsboro; }
      .flowering-scroll .flowering-legend .action-table .colors > * {
        height: calc(100%/6);
        padding: 2px;
        background-clip: border-box;
        font-size: 16px;
        text-align: center;
        align-items: center;
        border: 1px solid;
        border-bottom: none;
        border-right: none; }
      .flowering-scroll .flowering-legend .action-table .colors .very-high {
        background-color: #ef3b2c; }
      .flowering-scroll .flowering-legend .action-table .colors .high {
        background-color: #ff8a4a; }
      .flowering-scroll .flowering-legend .action-table .colors .medium {
        background-color: #fffa22; }
      .flowering-scroll .flowering-legend .action-table .colors .low {
        background-color: #74c476; }
      .flowering-scroll .flowering-legend .action-table .colors .very-low {
        background-color: #6baed6;
        border-bottom: 1px solid; }
      .flowering-scroll .flowering-legend .action-table .names > * {
        height: calc(100%/6);
        padding: 2px;
        font-size: 16px;
        text-align: center;
        align-items: center;
        border: 1px solid;
        border-bottom: none; }
      .flowering-scroll .flowering-legend .action-table .names .very-low {
        border-bottom: 1px solid; }
      .flowering-scroll .flowering-legend .action-table .borders-left > * {
        height: 20%;
        padding: 2px; }
      .flowering-scroll .flowering-legend .action-table .borders-left .very-high, .flowering-scroll .flowering-legend .action-table .borders-left .low {
        border: 1px solid;
        border-left: none;
        border-bottom: none; }
      .flowering-scroll .flowering-legend .action-table .borders-left .medium {
        width: 100%;
        display: grid;
        grid-auto-flow: row;
        padding: 0px; }
        .flowering-scroll .flowering-legend .action-table .borders-left .medium .medium-border {
          border-bottom: 1px solid;
          width: 100%; }
      .flowering-scroll .flowering-legend .action-table .borders-left .high, .flowering-scroll .flowering-legend .action-table .borders-left .very-low {
        border: 1px solid;
        border-top: none;
        border-left: none; }
      .flowering-scroll .flowering-legend .action-table .borders-right > * {
        height: 20%;
        padding: 2px; }
      .flowering-scroll .flowering-legend .action-table .borders-right .very-high, .flowering-scroll .flowering-legend .action-table .borders-right .low {
        border-bottom: 1px solid; }
      .flowering-scroll .flowering-legend .action-table .borders-right .medium {
        width: 100%;
        display: grid;
        grid-auto-flow: row;
        padding: 0px; }
        .flowering-scroll .flowering-legend .action-table .borders-right .medium .medium-border {
          border-bottom: 1px solid;
          width: 100%; }
      .flowering-scroll .flowering-legend .action-table .actions {
        display: grid;
        grid-template-rows: calc(100%/6) calc(100%/3) calc(100%/6) calc(100%/3); }
        .flowering-scroll .flowering-legend .action-table .actions > * {
          display: grid;
          padding: 2px;
          font-size: 16px;
          justify-items: center;
          align-items: center;
          border: 1px solid;
          border-left: none;
          border-bottom: none; }
        .flowering-scroll .flowering-legend .action-table .actions .low {
          border-bottom: 1px solid; }
  @media (min-height: 900px) {
    .flowering-scroll .flowering-legend {
      margin-top: 700px; } }

@media (min-height: 900px) {
  .flowering-scroll {
    height: 90vh; } }
