.report--container {
  position: fixed;
  top: 48px;
  width: 66vw;
  height: calc(100vh - 48px);
  right: calc(450px - 100%);
  transition: all .3s ease-out;
  background-color: white;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  z-index: 9; }
  .report--container.full-screen {
    width: 100%; }
  .report--container.visible {
    right: -66vw;
    z-index: 1001; 
  }
  .report--container.active {
    right: 0; }
    .report--container.active .container--toggler i {
      transform: rotate(0deg); }
  .report--container .container--toggler-left-not-active {
    top: 8px; }
  .report--container.toggler-hide {
    right: calc(422px - 100%); }
  .report--container header {
    height: 48px;
    padding: 6px 32px 6px 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end; }
    .report--container header .valign-wrapper {
      width: 24px;
      margin-right: 48px;
      cursor: pointer;
      user-select: none; }
    .report--container header .download-csv-data-button {
      margin-left: 24px;
      width: 15px;
      background-color: transparent;
      box-shadow: none;
      padding: 0; }
    .report--container header .download-csv-data-button > img {
      height: 35px;
      width: 35px; }
    .report--container header .header-div {
      display: flex;
      flex-grow: 3; }
      .report--container header .header-div .header-label {
        margin-top: 6px;
        margin-right: 16px; }
        .report--container header .header-div .header-label #bhbox-header-label {
          font-size: 14px; }
      .report--container header .header-div .header-div-item-outline a {
        outline: 0; }
      .report--container header .header-div .header-div-item-spacing {
        margin-right: 16px; }
      .report--container header .header-div input#fromPeriod, .report--container header .header-div input#toPeriod {
        margin: 5px 0px 0px 5px;
        height: 1.5rem;
        width: 50px;
        font-size: 13px;
        color: rgba(62, 39, 35, 0.87); }
      .report--container header .header-div button.green {
        width: 130px;
        color: white;
        height: 30px;
        margin-left: 10px; }
        .report--container header .header-div button.green span {
          color: inherit;
          height: inherit;
          width: 100%;
          padding: 0; }
      .report--container header .header-div h6 {
        font-size: 13px;
        margin: 0.7rem 0 0.4rem 0;
        color: rgba(62, 39, 35, 0.87); }
    .report--container header .header-graphic {
      align-items: center;
      display: flex;
      margin-left: 24px;
      cursor: pointer;
      user-select: none; }
      .report--container header .header-graphic span {
        align-items: center;
        display: flex; }
      .report--container header .header-graphic:hover {
        background-color: #eee; }
      .report--container header .header-graphic:focus {
        background-color: #eee; }
      .report--container header .header-graphic .bhbox-show {
        display: flex;
        width: 100%;
        color: rgba(62, 39, 35, 0.54); }
    .report--container header span {
      color: rgba(62, 39, 35, 0.54);
      height: 30px;
      width: 30px;
      padding: 0px 3px; }
      .report--container header span span {
        font-size: 13px; }
      .report--container header span i {
        font-size: 24px; }
    .report--container header .date-filter {
      color: rgba(62, 39, 35, 0.54); }
      .report--container header .date-filter .date-filter--label {
        float: left;
        margin-left: 8px; }
      .report--container header .date-filter .date-filter--select {
        margin: 0;
        margin-left: 8px; }
        .report--container header .date-filter .date-filter--select select {
          display: block;
          height: 36px;
          float: left; }
    .report--container header .weather-years-spacing {
      margin-left: 28px !important; }
    .report--container header .dropdown-div {
      z-index: 1;
      position: absolute;
      background-color: #fafafa;
      max-height: 80%;
      right: inherit;
      top: 1%;
      box-shadow: 5px 5px 5px #666; }
      @media screen and (max-height: 600px) {
        .report--container header .dropdown-div {
          max-height: 98%;
          box-shadow: 5px 5px 5px #666; } }
      @media screen and (min-height: 601px) and (max-height: 699px) {
        .report--container header .dropdown-div {
          max-height: 95%;
          width: 260px;
          box-shadow: 5px 5px 5px #666; } }
      .report--container header .dropdown-div .checkbox-list {
        margin-top: -20px; }
        .report--container header .dropdown-div .checkbox-list label {
          display: flex;
          font-size: 16px;
          line-height: normal;
          color: rgba(62, 39, 35, 0.7); }
        .report--container header .dropdown-div .checkbox-list .checkbox-item {
          padding-left: 16px; }
          .report--container header .dropdown-div .checkbox-list .checkbox-item .rc-checkbox-inner {
            font-size: 16px;
            border-color: #26A69A;
            border-width: 2px;
            width: 16px;
            height: 16px; }
            .report--container header .dropdown-div .checkbox-list .checkbox-item .rc-checkbox-inner::after {
              color: #26A69A;
              left: 3px;
              top: 1px; }
        .report--container header .dropdown-div .checkbox-list .rc-checkbox-checked .rc-checkbox-inner {
          background-color: #26A69A; }
        .report--container header .dropdown-div .checkbox-list .rc-checkbox-disabled .rc-checkbox-inner {
          border-color: #d9d9d9 !important; }
        .report--container header .dropdown-div .checkbox-list .not-checked {
          background-color: inherit !important; }
        .report--container header .dropdown-div .checkbox-list .checked {
          background-color: inherit !important; }
      .report--container header .dropdown-div .years-list {
        margin-top: 10px; }
        .report--container header .dropdown-div .years-list .radio-button-group {
          padding-left: 8px; }
      .report--container header .dropdown-div .radio-list {
        padding-left: 12px;
        margin-top: 5px; }
      .report--container header .dropdown-div .dropdown-buttons {
        display: inline-block;
        vertical-align: bottom;
        border-top: 0.5px solid rgba(0, 0, 0, 0.2);
        padding: 6px 6px 6px 6px;
        width: 100%; }
    .report--container header .dropdown-div-fullscreen {
      right: 45%; }
    .report--container header .period-dropdown {
      height: 20%; }
    .report--container header .weather-dropdown-variables {
      max-height: 220px; }
      @media screen and (max-height: 600px) {
        .report--container header .weather-dropdown-variables {
          max-height: 215px; } }
    .report--container header .cad-dropdown {
      height: 300px; }
    .report--container header .visualization-type {
      height: 120px; }
      @media screen and (max-height: 600px) {
        .report--container header .cad-dropdown {
          height: 300px; }
        .report--container header .visualization-type {
            height: 120px; } }
      .report--container header .cad-dropdown .dropdown-scroll {
        height: 85%; }
    .report--container header .graphic-dropdown-fullscreen {
      right: 60%; }
    .report--container header .graphic-dropdown-fullscreen2 {
      right: 45%; }
    .report--container header .farms-list {
      color: rgba(62, 39, 35, 0.54);
      margin: 0; }
      .report--container header .farms-list li {
        float: left; }
      .report--container header .farms-list .farms-list-item {
        text-align: center;
        margin-left: 5px; }
        .report--container header .farms-list .farms-list-item a {
          height: 36px;
          width: 36px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          border: 1px solid rgba(62, 39, 35, 0.87);
          border-radius: 50%;
          color: rgba(62, 39, 35, 0.87);
          opacity: .3;
          font-weight: 500;
          font-size: 20px; }
        .report--container header .farms-list .farms-list-item.active a {
          opacity: 1; }
  .report--container .report--content {
    padding: 0 0px;
    padding-left: 20px;
    z-index: 0;
    margin-top: 8px;
    height: 86%; }
    .report--container .report--content img {
      width: 100%; }
    .report--container .report--content .x-axis-name {
      position: absolute;
      text-align: center;
      bottom: 2%;
      left: 50%; }
    .report--container .report--content .weather--labels-graphic-unit {
      position: absolute;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      font-size: 14px;
      margin-top: 25%; }
    .report--container .report--content .years-of-graphic {
      text-align: center; }
      .report--container .report--content .years-of-graphic label {
        font-size: 16px;
        font-weight: 400;
        color: rgba(62, 39, 35, 0.54); }
  .report--container .schedule-container {
    display: flex;
    align-items: last baseline;
    position: absolute;
    top: 40px;
    right: 120px;
    height: 48px;
    padding: 6px 8px; }
    .report--container .schedule-container .select {
      color: rgba(62, 39, 35, 0.87);
      font-size: 13px; }
    .report--container .schedule-container span {
      margin-right: 4px;
      margin-left: 0px;
      cursor: default; }
      .report--container .schedule-container span.header-graphic:hover {
        background-color: #eee;
        cursor: pointer; }
      .report--container .schedule-container span.header-graphic:focus {
        background-color: #eee;
        cursor: pointer; }
  .report--container .multiple-farms {
    padding: 0;
    padding-right: 24px; }
  .report--container .bar-graphic {
    padding: 0; }
  .report--container .loading-bar {
    height: 6px;
    background-color: rgba(62, 39, 35, 0.54);
    position: absolute; }
  .report--container .farm-info-header-bhbox {
    height: 4%; }
  .report--container .farm-info-header-weather {
    height: 7%;
    margin-bottom: 0 !important; }
  .report--container .bhbox--labels-farm-name {
    font-size: 1.4rem;
    font-weight: 400;
    color: black;
    margin-top: 20px;
    padding-left: 18px; }
  .report--container .bhbox--labels-farm-name-weather {
    text-align: center; }
  .report--container .bhbox--labels-farm-cad {
    margin-top: 20px;
    text-align: right;
    font-size: 1.4rem;
    color: black;
    font-weight: 400; }
  .report--container .weather--labels-graphic-title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(62, 39, 35, 0.54);
    margin-top: 5px;
    margin-bottom: -20px;
    text-align: center; }
  .report--container .loading-screen {
    margin-top: 30px;
    text-align: center;
    background-color: rgba(62, 39, 35, 0.1);
    height: 428px;
    margin-left: 80px;
    margin-right: 24px;
    line-height: 394px;
    font-weight: 400;
    color: rgba(62, 39, 35, 0.54);
    font-size: 16px; }
  .report--container .loading-screen-prod {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    text-align: center;
    background-color: rgba(62, 39, 35, 0.1);
    height: 90%;
    margin-left: 24px;
    margin-right: 24px;
    font-weight: 400;
    color: rgba(62, 39, 35, 0.54);
    font-size: 16px; }
  .report--container .custom-tooltip {
    background-color: white;
    border: 1px solid black;
    width: 150px;
    padding: 10px;
    line-height: 2rem; }
  .report--container .label {
    padding-left: 8px;
    padding-top: 0px; }
  .report--container .desc {
    padding-left: 8px;
    margin-top: -10px; }
  .report--container .desc2 {
    padding-left: 8px;
    margin-top: -10px; }

.options-modal {
  margin: 8px 0px;
  z-index: 999 !important; }
  .options-modal .title {
    font-size: 1.3125rem;
    font-weight: 500;
    line-height: 1.16667em; }

.custom-tooltip-atr {
  background-color: white;
  padding: 10px;
  line-height: 2rem;
  border: 1px solid black;
  min-width: 180px; }
  .custom-tooltip-atr .label {
    padding-left: 8px;
    padding-top: 0px; }
  .custom-tooltip-atr .desc {
    padding-left: 8px;
    margin-top: -10px; }
  .custom-tooltip-atr .desc2 {
    padding-left: 8px;
    margin-top: -10px; }

.custom-tooltip2 {
  padding: 10px;
  line-height: 2rem;
  background-color: white;
  border: 1px solid black;
  width: 180px;
  padding: 10px;
  line-height: 2rem; }
  .custom-tooltip2 .label {
    padding-left: 8px;
    padding-top: 0px; }
  .custom-tooltip2 .desc {
    padding-left: 8px;
    margin-top: -10px; }
  .custom-tooltip2 .desc2 {
    padding-left: 8px;
    margin-top: -10px; }

.dropdown-scroll {
  height: 71%; }

.dropdown-year-scroll {
  height: 300px; }
  @media screen and (min-height: 800px) {
    .dropdown-year-scroll {
      height: 400px; } }
  @media screen and (min-height: 900px) {
    .dropdown-year-scroll {
      height: 500px; } }
  @media screen and (min-height: 1000px) {
    .dropdown-year-scroll {
      height: 600px; } }

.farms-dropdown-scroll {
  height: 400px; }
  @media screen and (min-height: 800px) {
    .farms-dropdown-scroll {
      height: 500px;
    }
  }
  @media screen and (min-height: 900px) {
    .farms-dropdown-scroll {
      height: 580px; 
    } 
  }

.bhbox-title {
  text-align: center;
  margin-bottom: 2px; }
  .bhbox-title label {
    font-size: 1rem;
    color: #737373; }

.compare-farms-bar {
  height: 100vh;
  background-color: white;
  position: absolute;
  z-index: 99;
  top: 0px;
  right: 0;
  width: 30vw;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
  .compare-farms-bar .compare-farms-bar-header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px !important;
    background-color: white;
    height: 128px; }
    .compare-farms-bar .compare-farms-bar-header .row {
      padding: 8px 16px 0 16px; }
      .compare-farms-bar .compare-farms-bar-header .row .modal-label-1 {
        font-size: 18px;
        color: #26a69a; }
      .compare-farms-bar .compare-farms-bar-header .row .modal-label-2 {
        font-size: 15px;
        color: #26a69a; }
  .compare-farms-bar .compare-farms-bar-points {
    height: 72%;
    padding: 16px 0px 0 16px; }
    .compare-farms-bar .compare-farms-bar-points .compare-farms-bar-points-scroll {
      width: 100%;
      height: 95%;
      padding-right: 16px; }
    .compare-farms-bar .compare-farms-bar-points .compare-farms-bar-points-title {
      color: #26a69a;
      font-size: 18px;
      text-transform: uppercase; }
    .compare-farms-bar .compare-farms-bar-points .compare-farms-bar-points-subtitle {
      color: #9e9e9e;
      font-size: 14px;
      margin-top: 5px; }
      .compare-farms-bar .compare-farms-bar-points .compare-farms-bar-points-subtitle .compare-farms-bar-points-subtitle-points {
        text-align: center; }
      .compare-farms-bar .compare-farms-bar-points .compare-farms-bar-points-subtitle .compare-farms-bar-points-subtitle-cad {
        text-align: center; }
    .compare-farms-bar .compare-farms-bar-points ul li {
      clear: both;
      height: 40px;
      padding: 8px 0 8px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 45px; }
      .compare-farms-bar .compare-farms-bar-points ul li .list-label {
        width: 77%;
        float: left;
        margin-top: 9px; }
        .compare-farms-bar .compare-farms-bar-points ul li .list-label i {
          font-size: 16px; }
        .compare-farms-bar .compare-farms-bar-points ul li .list-label label {
          padding-left: 32px;
          font-size: 15px; }
      .compare-farms-bar .compare-farms-bar-points ul li .select-field {
        width: 23%;
        float: right;
        margin-top: 0; }
      .compare-farms-bar .compare-farms-bar-points ul li select:active {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; }
      .compare-farms-bar .compare-farms-bar-points ul li select option:active {
        background-color: white;
        color: rgba(62, 39, 35, 0.87);
        padding: 8px 8px 8px 0px;
        text-shadow: 0 0 0 rgba(62, 39, 35, 0.87);
        display: block;
        background-color: white;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        height: 32px;
        border-bottom: 1px solid rgba(62, 39, 35, 0.54);
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../../../images/down_arrow.png");
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: right; }
  .compare-farms-bar .compare-farms-bar-btns {
    font-weight: 500;
    padding: 8px;
    clear: right; }
    .compare-farms-bar .compare-farms-bar-btns .btn-ok {
      float: right;
      color: white;
      background-color: #4CAF50 !important; }

.button-compare-bank-user {
  margin-right: 16px; }

.soil-dropdown {
  height: 30% !important; }

.bhbox-title-div {
  margin-bottom: 0 !important;
  height: 3%; }

.index-title-div {
  margin-bottom: 32px !important;
  height: 3%; }
  .index-title-div div label {
    font-size: 1.5rem; }

.schedule {
  margin-top: 32px;
  height: 80%; }
  .schedule .table {
    height: 100%; }

.productivity {
  margin-bottom: 20px; }
  .productivity .productivity-scroll {
    height: 80vh;
    position: absolute;
    width: 100%;
    margin-top: 8px; }
  .productivity .productivity-header {
    height: 64px; }
    .productivity .productivity-header .prod-month-container {
      width: 15%;
      display: flex;
      align-items: center; }
      .productivity .productivity-header .prod-month-container .header-div-item-outline {
        width: 23%; }
        .productivity .productivity-header .prod-month-container .header-div-item-outline .button--retangle-icon-text {
          font-size: 1rem; }
        .productivity .productivity-header .prod-month-container .header-div-item-outline .dropdown-div-2 {
          z-index: 1;
          position: absolute;
          background-color: #fafafa;
          right: inherit;
          top: 1%;
          width: 260px;
          box-shadow: 5px 5px 5px #666; }
          .productivity .productivity-header .prod-month-container .header-div-item-outline .dropdown-div-2 .button--retangle-active .button--retangle-icon-text {
            display: flex;
            align-items: center; }
          .productivity .productivity-header .prod-month-container .header-div-item-outline .dropdown-div-2 .checkbox-list {
            padding: 8px 16px 16px 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap; }
            .productivity .productivity-header .prod-month-container .header-div-item-outline .dropdown-div-2 .checkbox-list .month-selects-container {
              flex-basis: 100%;
              display: flex;
              align-items: center; }
            .productivity .productivity-header .prod-month-container .header-div-item-outline .dropdown-div-2 .checkbox-list .prod-text-field {
              margin-bottom: 4px; }
            .productivity .productivity-header .prod-month-container .header-div-item-outline .dropdown-div-2 .checkbox-list .first-prod-text-field {
              margin-top: 8px; }
          .productivity .productivity-header .prod-month-container .header-div-item-outline .dropdown-div-2 .dropdown-buttons {
            padding: 0px 8px 8px 8px;
            text-align: right;
            display: flex;
            justify-content: flex-end; }
    .productivity .productivity-header .prod-options-container {
      width: 85%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .productivity .productivity-header .prod-options-container .prod-crop-container {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-right: 8px; }
      .productivity .productivity-header .prod-options-container .header-div-item-outline {
        width: 23%; }
        .productivity .productivity-header .prod-options-container .header-div-item-outline .button--retangle-icon-text {
          font-size: 1rem; }
        .productivity .productivity-header .prod-options-container .header-div-item-outline .dropdown-div-2 {
          z-index: 1;
          position: absolute;
          background-color: #fafafa;
          right: inherit;
          top: 1%;
          width: 260px;
          box-shadow: 5px 5px 5px #666; }
          .productivity .productivity-header .prod-options-container .header-div-item-outline .dropdown-div-2 .checkbox-list {
            padding: 8px 16px 0px 16px; }
            .productivity .productivity-header .prod-options-container .header-div-item-outline .dropdown-div-2 .checkbox-list .prod-text-field {
              margin-bottom: 4px; }
            .productivity .productivity-header .prod-options-container .header-div-item-outline .dropdown-div-2 .checkbox-list .first-prod-text-field {
              margin-top: 8px; }
          .productivity .productivity-header .prod-options-container .header-div-item-outline .dropdown-div-2 .dropdown-buttons {
            padding: 0px 8px 8px 8px;
            text-align: right;
            display: flex;
            justify-content: flex-end; }
      .productivity .productivity-header .prod-options-container .text-fields {
        width: 24%; }
      .productivity .productivity-header .prod-options-container .kdec-field {
        width: 6%;
        margin-right: 8px; }
      .productivity .productivity-header .prod-options-container .recalculate-button {
        background-color: #6aa426;
        margin-right: 8px;
        width: 100px;
        height: 35px; }
        .productivity .productivity-header .prod-options-container .recalculate-button span {
          color: white; }
  .productivity .title {
    font-size: 18px;
    margin-top: 8px; }
  .productivity .subtitle {
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 8px; }
  .productivity .general-graph {
    height: 40%;
    width: 95%;
    margin: 0 auto;
    padding-top: 10px; }
  .productivity .botton-graphs {
    height: 100%;
    width: 90%;
    padding-top: 195px;
    margin: 0 auto; }
    @media screen and (max-height: 628px) {
      .productivity .botton-graphs {
        padding-top: 220px; } }

.prod-months-dropdown {
  border-bottom: 1px solid white !important; }
  .prod-months-dropdown::before {
    border: 1px solid white !important; }
  .prod-months-dropdown::after {
    border: 1px solid white !important; }

.prod-calendar-icon {
  font-size: 36px !important;
  color: #6aa426;
  cursor: pointer; }

.general-prod-disclaimer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  font-style: italic;
  margin-right: 8px; }

.general-prod-disclaimer > span {
  color: red; }
